/**
 * Gets the day of the year from a Date object.
 *
 * Use new Date() and Date.prototype.getFullYear() to get the first day of the year as a Date object,
 * subtract it from the provided date and divide with the milliseconds in each day to get the result.
 * Use Math.floor() to appropriately round the resulting day count to an integer.
 *
 * @example dayOfYear(new Date()); // 272
 *
 * @param date
 * @return {number}
 */
export const dayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24)

/**
 * Returns the human readable format of the given number of milliseconds.
 *
 * Divide ms with the appropriate values to obtain the appropriate values for day, hour, minute, second and millisecond.
 * Use Object.entries() with Array.prototype.filter() to keep only non-zero values. Use Array.prototype.map() to create the
 * string for each value, pluralizing appropriately. Use String.prototype.join(', ') to combine the values into a string.
 *
 * @example
 *
 * @param ms
 * @return {string}
 */
export const formatDuration = ms => {
  if (ms < 0) ms = -ms
  const time = {
    day        : Math.floor(ms / 86400000),
    hour       : Math.floor(ms / 3600000) % 24,
    minute     : Math.floor(ms / 60000) % 60,
    second     : Math.floor(ms / 1000) % 60,
    millisecond: Math.floor(ms) % 1000
  }
  return Object.entries(time)
    .filter(val => val[1] !== 0)
    .map(([key, val]) => `${ val } ${ key }${ val !== 1 ? 's' : '' }`)
    .join(', ')
}

/**
 * Returns a string of the form HH:MM:SS from a Date object.
 *
 * Use Date.prototype.toTimeString() and String.prototype.slice() to get the HH:MM:SS part of a given Date object.
 *
 * @example getColonTimeFromDate(new Date()); // "08:38:00"
 *
 * @param date
 * @return {string}
 */
export const getColonTimeFromDate = date => date.toTimeString().slice(0, 8)

/**
 * Returns the difference (in days) between two dates.
 *
 * Calculate the difference (in days) between two Date objects.
 *
 * @example getDaysDiffBetweenDates(new Date('2017-12-13'), new Date('2017-12-22')); // 9
 *
 * @param dateInitial
 * @param dateFinal
 * @return {number}
 */
export const getDaysDiffBetweenDates = (dateInitial, dateFinal) => (dateFinal - dateInitial) / (1000 * 3600 * 24)

/**
 * Converts an integer to a suffixed string, adding am or pm based on its value.
 *
 * Use the modulo operator (%) and conditional checks to transform an integer to a stringified 12-hour format with meridiem suffix.
 *
 * @example getMeridiemSuffixOfInteger(0); // "12am"
 * @example getMeridiemSuffixOfInteger(11); // "11am"
 * @example getMeridiemSuffixOfInteger(13); // "1pm"
 * @example getMeridiemSuffixOfInteger(25); // "1pm"
 *
 * @param num
 * @return {string}
 */
export const getMeridiemSuffixOfInteger = num => num === 0 || num === 24 ? 12 + 'am' : num === 12 ? 12 + 'pm' : num < 12 ? (num % 12) + 'am' : (num % 12) + 'pm'

/**
 * Check if a date is after another date.
 *
 * Use the greater than operator (>) to check if the first date comes after the second one.
 *
 * @example isAfterDate(new Date(2010, 10, 21), new Date(2010, 10, 20)); // true
 *
 * @param dateA
 * @param dateB
 * @return {boolean}
 */
export const isAfterDate = (dateA, dateB) => dateA > dateB

/**
 * Check if a date is before another date.
 *
 * Use the less than operator (<) to check if the first date comes before the second one.
 *
 * @example isBeforeDate(new Date(2010, 10, 20), new Date(2010, 10, 21)); // true
 *
 * @param dateA
 * @param dateB
 * @return {boolean}
 */
export const isBeforeDate = (dateA, dateB) => dateA < dateB

/**
 * Check if a date is the same as another date.
 *
 * Use Date.prototype.toISOString() and strict equality checking (===) to check if the first date is the same as the second one.
 *
 * @example isSameDate(new Date(2010, 10, 20), new Date(2010, 10, 20)); // true
 *
 * @param dateA
 * @param dateB
 * @return {boolean}
 */
export const isSameDate = (dateA, dateB) => dateA.toISOString() === dateB.toISOString()

/**
 * Results in a boolean representation of a specific date.
 *
 * Pass the specific date object firstly. Use Date.getDay() to check weekday by using a modulo operator and then returning a boolean.
 *
 * @example isWeekday(); // true (if current date is 2019-07-19)
 *
 * @param t
 * @return {boolean}
 */
export const isWeekday = (t = new Date()) => {
  return t.getDay() % 6 !== 0
}

/**
 * Results in a boolean representation of a specific date.
 *
 * Pass the specific date object firstly. Use Date.getDay() to check weekend based on the day being returned as 0 - 6 using a modulo operation then return a boolean.
 *
 * @example isWeekend(); // 2018-10-19 (if current date is 2018-10-18)
 *
 * @param t
 * @return {boolean}
 */
export const isWeekend = (t = new Date()) => {
  return t.getDay() % 6 === 0
}

/**
 * Returns the maximum of the given dates.
 *
 * Use the ES6 spread syntax with Math.max to find the maximum date value, new Date() to convert it to a Date object.
 *
 * @example
 * const array = [
 * new Date(2017, 4, 13),
 * new Date(2018, 2, 12),
 * new Date(2016, 0, 10),
 * new Date(2016, 0, 9)
 * ];
 * maxDate(array); // 2018-03-11T22:00:00.000Z
 *
 * @param dates
 * @return {Date}
 */
export const maxDate = dates => new Date(Math.max(...dates))

/**
 * Returns the minimum of the given dates.
 *
 * Use the ES6 spread syntax to find the minimum date value, new Date() to convert it to a Date object.
 *
 * @example
 * const array = [
 * new Date(2017, 4, 13),
 * new Date(2018, 2, 12),
 * new Date(2016, 0, 10),
 * new Date(2016, 0, 9)
 * ];
 * minDate(array); // 2016-01-08T22:00:00.000Z
 *
 * @param dates
 * @return {Date}
 */
export const minDate = dates => new Date(Math.min(...dates))

/**
 * Results in a string representation of tomorrow's date.
 *
 * Use new Date() to get the current date, increment by one using Date.getDate() and set the value to the result using Date.setDate().
 * Use Date.prototype.toISOString() to return a string in yyyy-mm-dd format.
 *
 * @example tomorrow(); // 2018-10-19 (if current date is 2018-10-18)
 *
 * @return {string}
 */
export const tomorrow = () => {
  const t = new Date()
  t.setDate(t.getDate() + 1)
  return t.toISOString().split('T')[0]
}

/**
 * Results in a string representation of yesterday's date.
 *
 * Use new Date() to get the current date, decrement by one using Date.getDate() and set the value to the result using Date.setDate().
 * Use Date.prototype.toISOString() to return a string in yyyy-mm-dd format.
 *
 * @example yesterday(); // 2018-10-17 (if current date is 2018-10-18)
 *
 * @return {string}
 */
export const yesterday = () => {
  const t = new Date()
  t.setDate(t.getDate() - 1)
  return t.toISOString().split('T')[0]
}
