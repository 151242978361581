export const arrayUtils = require('./array/index')
export const browserUtils = require('./browser/index')
export const colorUtils = require('./color/index')
export const dateUtils = require('./date/index')
export const functionUtils = require('./function/index')
export const greekUtils = require('./greek/index')
export const helperUtils = require('./helper/index')
export const mathUtils = require('./math/index')
export const objectUtils = require('./object/index')
export const stringUtils = require('./string/index')
export const typeUtils = require('./type/index')
export const themeUtils = require('./theme/index')

const utils = {
  array   : arrayUtils,
  browser : browserUtils,
  color   : colorUtils,
  date    : dateUtils,
  function: functionUtils,
  greek   : greekUtils,
  helper  : helperUtils,
  math    : mathUtils,
  object  : objectUtils,
  string  : stringUtils,
  type    : typeUtils,
  theme   : themeUtils
}

export default utils
