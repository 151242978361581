<template>
  <v-btn
    class="pa-2 tp-button"
    depressed
    height="auto"
    tile
    v-bind="$attrs"
    x-large
  >
    <div
      :style="`max-width: ${$attrs.maxWidth ? $attrs.maxWidth - 16 : $attrs.width ? $attrs.width - 16 : 'auto'}px; width: ${$attrs.width ? $attrs.width - 16 : 'auto'}px;`"
      class="d-inline-flex px-4 align-center"
      style="border: 1px solid #FFFFFF; width: 100%; height: 100%; padding: 8px; white-space: normal;"
    >
      <slot name="prepend" />

      <slot />

      <slot name="append" />
    </div>
  </v-btn>
</template>

<script>
export default {
  name      : 'CmsButton',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  enums     : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
.tp-button {
  background         : linear-gradient(90deg, #3047B0 25%, #0087FF 90%);
  box-shadow         : 0 0 16px rgba(105, 10, 97, 0.9);
  -webkit-box-shadow : 0 0 16px rgba(105, 10, 97, 0.9);
  -moz-box-shadow    : 0 0 16px rgba(105, 10, 97, 0.9);
}
</style>
