<template>
  <v-container
    v-resize="onResize"
    fluid
    pa-0
  >
    <v-row class="">
      <v-col cols="12">
        <video-background
          :style="$vuetify.breakpoint.mdAndUp ? `position: absolute; height: 100vh; width: 100vw;` : `height: 250px; width: 100%;`"
          poster="/img/pages/services/bg.jpg"
          src=""
        >
          <v-container
            fluid
            pa-0
          >
            <v-row>
              <v-col
                class="pa-0"
                cols="12"
                md="6"
              >
                <div :style="`background-image: url('/img/pages/services/overlay.png'); pointer-events: none; z-index: 1; margin-left: 12px; margin-top: 12px; position:absolute; background-repeat:no-repeat; background-size:cover; background-position:top left; height: ${$vuetify.breakpoint.mdAndUp ? '100vh' : '250px'}; width: 100vw;`" />
                <div style="position: absolute; height: 100%; width: 100%">
                  <div :style="`border: 3px solid green; background-color: green; position: absolute; transform: scale(${scaleFactorAll});`">
                    <div :style="`border: 3px solid black; background-color: black; position: absolute; top: 450px;`">
                      <div
                        ref="circlesHolder"
                        class="circlesHolder"
                      />
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col
                v-if="$vuetify.breakpoint.mdAndUp"
                cols="6"
                style="outline: 0px cyan solid;"
              >
                <div :style="`height: 100vh; width: 100%; position: relative; left: -65px; background-color: ${data.Items[step].Color};`" />
              </v-col>
            </v-row>
          </v-container>
        </video-background>
      </v-col>
    </v-row>

    <v-row
      class="mt-0 px-3"
      style="isolation: isolate; pointer-events: none;"
    >
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="6"
      />

      <v-col
        :style="`background-color: ${data.Items[step].Color};`"
        cols="12"
        md="6"
        style="pointer-events: all;"
      >
        <div :style="`outline: 0px green solid; margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight - 15 : 0}px; padding-top: ${$dataStore.App.Config.SectionPadding}px;`">
          <slide-x-left-transition :delay="200">
            <v-window
              v-show="animateItems"
              v-model="step"
              :show-arrows="true"
            >
              <template v-for="(item, index) in data.Items">
                <v-window-item :key="`slide-${index}`">
                  <v-card
                    :style="$vuetify.breakpoint.mdAndUp ?
                      `height: calc(100vh - ${$dataStore.App.Config.ToolbarHeight}px - ${$dataStore.App.Config.FooterHeight}px - ${$dataStore.App.Config.SectionPadding*4}px)` :
                      `height: calc(100vh - 300px - ${$dataStore.App.Config.FooterHeight - 26}px)`
                    "
                    class="overflow-y-auto overflow-x-hidden"
                    color="transparent"
                    flat
                  >
                    <v-row
                      align="center"
                      class="fill-height"
                      justify="center"
                    >
                      <v-card-text class="px-14">
                        <h2
                          v-if="item.Title"
                          class="section-title mb-6"
                          style="max-width: 500px"
                          v-html="item.Title"
                        />

                        <div
                          v-if="item.Body"
                          class="section-body"
                          style="max-width: 500px"
                          v-html="item.Body"
                        />
                      </v-card-text>
                    </v-row>
                  </v-card>
                </v-window-item>
              </template>
            </v-window>
          </slide-x-left-transition>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import { gsap }      from 'gsap'

const degreesStep = 22.5
const pointsSize = 50

export default {
  name      : 'Services',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      animateItems: false,
      selectedItem: null,
      step        : 0,
      windowSize  : {
        x: 0,
        y: 0
      },
      scaleFactor: {
        x: 0,
        y: 0
      },
      data: {
        Items: [
          {
            Color: '#00ab96',
            Title: 'Omnichannel services',
            Body : `
              <p>A seamless, cutting edge omnichannel customer engagement solution.  We support different service channels voice, chat, social media, and email. A holistic approach for a smooth customer experience regardless of background, language, or age.</p>
              <p>Each channel provides accurate and reliable data visualized via Advanced Descriptive Statistics and BI tools. Data-driven, actions valid decision-making, structured quality control, security and process optimization.</p>
            `
          },
          {
            Color: '#0096db',
            Title: 'Operational services',
            Body : `
              <p>Our operational services deliver based on top industry standards.</p>
              <p><img src="/img/pages/services/img-1.png" width="100%" height="auto"></p>
            `
          },
          {
            Color: '#ea5b0b',
            Title: 'Total Quality management',
            Body : `
              <p>Our TQM ensures long-term success through Customer & Client satisfaction. Increases our adaptability, performance and leads to an increased customer loyalty and satisfaction.</p>
            `
          },
          {
            Color: '#28348b',
            Title: 'Workforce Management ',
            Body : `
              <p>The AI Forecasting which simulates historical volumes and selects the best forecasting mechanism.</p>
              <p>For our Automotive Clients, we propose specific methodologies that need to be implemented in order to manage this properly. Our unique, patented tools are based on AI and advanced data processing.</p>
              <p><strong>Our structure for our Automotive Clients:</strong></p>
              <ul>
                <li><strong>Market Managers:</strong> Customized support of the uniqueness of each client.</li>
                <li><strong>Car Specialists:</strong> Advanced expertise to support a variety of complex car models.</li>
                <li><strong>Escalation Management:</strong> Cause & complex escalations to reduce the cost but also improve the experience of the customers.</li>
                <li><strong>KB Developers:</strong> Up to date Sophisticated KBS which can also maintain communication with the manuals on the car and the digital screen.</li>
                <li><strong>VIP Support:</strong> Expensive cars that require for VIP support with priority Queue, video Calls and SVA support sessions. Agents with advanced profile, hard & soft skills are required for this service.</li>
              </ul>
            `
          }
        ]
      }
    }
  },
  computed: {
    scaleFactorAll () {
      return this.scaleFactor.x < this.scaleFactor.y ? this.scaleFactor.x < 1 ? this.scaleFactor.x : 1 : this.scaleFactor.y < 1 ? this.scaleFactor.y : 1
    }
  },
  watch: {
    step (newVal) {
      this.selectMenuItem(newVal)
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.animateItems = true
    this.initMenu()
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.scaleFactor = { x: window.innerWidth / 1920, y: window.innerHeight / 1080 }
      setTimeout(() => {
        this.selectMenuItem(this.step, true)
      }, 250)
    },

    initMenu () {
      const circlesHolder = this.$refs.circlesHolder

      const createCircles = (dataItems, radius) => {
        if (!circlesHolder) return

        // while (circlesHolder.firstChild) circlesHolder.removeChild(circlesHolder.firstChild)

        for (let i = 0; i < dataItems.length; i++) {
          // const rotation = i * (360 / numCircles)
          const rotation = degreesStep * i
          const circleInner = document.createElement('div')
          const circle = document.createElement('div')
          const circleParent = document.createElement('div')
          circleInner.setAttribute('class', 'circleInner')
          circle.setAttribute('class', 'circle')
          circleParent.setAttribute('class', 'circleParent')
          circleInner.innerHTML = `<span>${ dataItems[i].Title }</span>`
          circle.index = i
          circle.style.backgroundColor = dataItems[i].Color
          circle.addEventListener('click', onCircleClick, false)
          gsap.set(circlesHolder, { width: radius * 2, height: radius * 2 })
          gsap.set(circle, { x: radius - pointsSize / 2 - 7, yPercent: -50, width: pointsSize, height: pointsSize })
          gsap.set(circleParent, { transformOrigin: 'left center', width: radius - pointsSize / 2 - 7, rotation: rotation, left: '50%', top: '50%' })
          circlesHolder.append(circleParent)
          circleParent.append(circleInner)
          circleParent.append(circle)
          // counter rotate to get all circles to be upright
          // gsap.set(circle, { rotation: -i * (360 / numCircles) })
          gsap.set(circle, { rotation: -i * degreesStep })
        }

        this.selectMenuItem(0)
      }

      const onCircleClick = (e) => {
        this.selectMenuItem(e?.currentTarget?.index ?? 0)
      }

      createCircles(this.data.Items, 250)
    },

    selectMenuItem (index, allowSame = false) {
      const currentTarget = document.querySelector(`.circlesHolder div:nth-child(${ index + 1 }) .circle`)

      if ((this.selectedItem === index && !allowSame) || !currentTarget) return

      const diff = Math.abs(this.selectedItem - index)
      const isNegative = this.selectedItem > index

      gsap.to('.circle', { width: pointsSize, height: pointsSize, marginLeft: 0, duration: 0.25 })
      gsap.to('.circleInner span', { opacity: 0, duration: 0 })
      gsap.to('.circleInner', { width: 0, opacity: 0, duration: 0.25 })

      gsap.to('.circlesHolder', { rotation: `${ isNegative ? '+' : '-' }=${ diff * degreesStep }_short`, duration: 0.25, ease: 'none' })
      gsap.to('.circle', { rotation: `${ isNegative ? '-' : '+' }=${ diff * degreesStep }_short`, duration: 0.25, ease: 'none' })
      this.selectedItem = this.step = parseInt(index)

      gsap.to(currentTarget, { width: pointsSize + 30, height: pointsSize + 30, marginLeft: -18, duration: 0.25, delay: 0.25 })
      gsap.to(currentTarget.parentElement.firstElementChild, { width: this.$vuetify.breakpoint.mdAndUp ? 550 : 1500, opacity: 1, duration: 0.25, delay: 0.25 })
      gsap.to(currentTarget.parentElement.firstElementChild.firstElementChild, { opacity: 1, duration: 0.25, delay: 0.50 })
    }
  }
}
</script>

<style scoped>
/deep/ .vue-responsive-videobg .video-buffering {
  background-position : top left;
}

/deep/ .vue-responsive-videobg .video-wrapper video {
  object-fit      : cover;
  object-position : top left;
}

/deep/ .v-window__next, /deep/ .v-window__prev {
  margin : 0;
}

/deep/ .circlesHolder {
  width         : 330px;
  height        : 330px;
  border-radius : 50%;
  border        : 14px solid #FFFFFF;
  position      : absolute;
  left          : -165px;
  z-index       : 0;
}

/deep/ .circle {
  text-align    : center;
  border-radius : 50%;
  background    : #00AB96;
  position      : absolute;
  border        : 6px solid #FFFFFF;
  cursor        : pointer;
}

/deep/ .circleParent {
  /*
  width      : 100px;
  height     : 2px;
  background : red;
  */
  position : absolute;
}

/deep/ .circleInner {
  position    : absolute;
  display     : flex;
  height      : 100%;
  left        : 100%;
  align-items : center;
  padding     : 6px;
  opacity     : 0;
}

/deep/ .circleInner span {
  font-size   : 35px;
  line-height : initial;
  font-weight : bold;
  padding     : 12px;
  opacity     : 0;
}

/deep/ .circleInner:before {
  content          : '';
  flex             : 1;
  height           : 6px;
  background-color : #FFFFFF;
}

@media screen and (max-width : 959px) {
  /deep/ .circleInner span {
    font-size   : 100px;
    line-height : 70px;
  }
}
</style>
