<template>
  <v-navigation-drawer
    v-model="navigationDrawerVisible"
    app
    color="black"
    floating
    right
    temporary
    width="425"
  >
    <template
      v-if="logoVisible"
      #prepend
    >
      <v-toolbar
        color="black"
        flat
      >
        <router-link :to="{name: 'Home'}">
          <img
            class="ma-2"
            height="30"
            src="/img/logo/logo-light.png"
          >
        </router-link>

        <v-spacer />

        <v-btn
          icon
          @click="navigationDrawerVisible = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
    </template>

    <v-list
      class="ml-6"
      nav
    >
      <template v-for="(item, i) in items">
        <v-list-item
          :key="`item-${i}`"
          :exact="item.route.exact"
          :to="item.route"
          active-class="list-item-active"
          class="my-4"
          exact-active-class="list-item-active"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title style="font-size: 20px;">
              {{ item.text.toLocaleUpperCase($i18n.locale) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <!--
      <v-list-item
        :to="{name: 'Contact'}"
        active-class="list-item-active"
        class="mt-12"
        exact-active-class="list-item-active"
      >
        <div
          style="width: 25px; height: 24px; background-color: #7A2182; position: absolute; left: -32px"
        />

        <v-list-item-content>
          <v-list-item-title style="font-size: 20px;">
            CONTACT US
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name      : 'AppNavigationDrawer',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    logoVisible: {
      type   : Boolean,
      default: true
    },
    navigationDrawer: {
      type    : Boolean,
      required: true
    },
    items: {
      type    : Array,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
.list-item-active {
  color       : #7A2182;
  font-weight : bold;
}
</style>
