<template>
  <v-container
    fluid
    pa-0
  >
    <v-row class="">
      <v-col
        cols="12"
        lg="6"
        md="8"
        sm="12"
      >
        <video-background
          :style="$vuetify.breakpoint.mdAndUp ? `position: absolute; height: 100vh; width: 100vw;` : `height: 250px; width: 100%;`"
          poster="/video/home.jpg"
          src="/video/home.mp4"
        >
          <v-container
            v-if="$vuetify.breakpoint.mdAndUp"
            fluid
            pa-0
          >
            <v-row>
              <v-col
                cols="12"
                lg="6"
                md="8"
                sm="12"
              >
                <div :style="`height: 100vh; width: 100%; background-color: #28348B; opacity: 0.75; mix-blend-mode: multiply;`" />
              </v-col>
            </v-row>
          </v-container>
        </video-background>
      </v-col>
    </v-row>

    <v-row
      :style="$vuetify.breakpoint.mdAndUp ? `` : `background-color: #28348B;`"
      class="mt-0 px-3"
      style="isolation: isolate;"
    >
      <v-col
        cols="12"
        lg="6"
        md="8"
        sm="12"
        style="border: 0px red solid;"
      >
        <div :style="`margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight - 15 : 0}px; padding-top: ${$dataStore.App.Config.SectionPadding}px;`">
          <div
            :style="$vuetify.breakpoint.mdAndUp ?
              `padding: ${$dataStore.App.Config.SectionPadding}px; height: calc(100vh - ${$dataStore.App.Config.ToolbarHeight + $dataStore.App.Config.FooterHeight + $dataStore.App.Config.SectionPadding * 2}px);` :
              `padding: ${$dataStore.App.Config.SectionPadding}px; height: calc(100vh - 300px - ${$dataStore.App.Config.FooterHeight - 26}px);`"
            class="overflow-y-auto"
            style="border: 0px green solid;"
          >
            <div style="border: 0px orange solid;">
              <cms-text-block>
                <template #title>
                  Driving towards customer<br>satisfaction & Business excellence.
                </template>
                <template #body>
                  <p>A multilingual hub catering more than 60 clients in 45 languages and dialects across 145+ markets with more than 10.000 passionate employees. We are experiencing firsthand how digitization, increasing automation, and new business models are revolutionizing industries across all verticals.</p>
                </template>
              </cms-text-block>

              <cms-feature-block
                :item-size="$vuetify.breakpoint.sm ? 100 : $vuetify.breakpoint.xs ? 80 : 120"
                :items="[
                  {Title: 'Customized solutions', Img: '/img/pages/home/feature-1.png', Route: {name: 'ValueProposition'}},
                  {Title: 'Top quality services and sustainability', Img: '/img/pages/home/feature-2.png', Route: {name: 'Services'}},
                  {Title: 'Customer, employee & financial value', Img: '/img/pages/home/feature-3.png', Route: {name: 'OurPeople'}},
                ]"
                class="mt-6"
                title="We are offering"
              />

              <cms-text-block class="pt-4">
                <template #body>
                  <p>To ensure that our results are sustainable we are pairing our lean management system with an Agile way of working. We bring people, processes & technology, together - to carry out a particular task by breaking it up into phases: Quick wins – Quick fails.</p>
                  <p>We are offering existing and prospect clients a 360 approach around process management & digital innovation. With us you are not dealing with a 3rd party vendor – but with a reliable partner that is here to help shape your company’s “next day”, and our common future.</p>
                </template>
              </cms-text-block>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18nRouteMeta   from '@/mixins/i18n/i18nRouteMeta'
import CmsTextBlock    from '@/theme/default/components/cms/CmsTextBlock'
import CmsFeatureBlock from '@/theme/default/components/cms/CmsFeatureBlock'

export default {
  name      : 'Home',
  components: { CmsFeatureBlock, CmsTextBlock },
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      animateItems: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.animateItems = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .vue-responsive-videobg .video-buffering {
  background-position : top center;
}

/deep/ .vue-responsive-videobg .video-wrapper video {
  object-fit      : cover;
  object-position : top center;
}
</style>
