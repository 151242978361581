<template>
  <v-container
    align-start
    fill-height
    fluid
    pa-0
  >
    <portal-target name="video-destination-desktop" />

    <v-row v-if="isMobile">
      <v-col cols="12">
        <portal-target name="video-destination-mobile" />
      </v-col>
    </v-row>

    <v-row
      class="mt-0 px-sm-0 px-md-3"
      style="isolation: isolate;"
    >
      <v-col
        class="pt-0 pt-md-3"
        cols="12"
        lg="5"
        md="6"
        style="border: 0px red solid;"
        xl="4"
      >
        <div :style="`margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight : 0}px; padding: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.SectionPadding : 0}px;`">
          <v-window v-model="step">
            <v-window-item :value="1">
              <slide-y-up-transition :delay="100">
                <v-card
                  v-show="animateItems"
                  :color="isMobile ? '#E60076' : 'transparent'"
                  flat
                  tile
                >
                  <v-card-text
                    :style="$vuetify.breakpoint.mdAndUp ?
                      `height: calc(100vh - 65px - 66px - ${$dataStore.App.Config.ToolbarHeight}px - ${$dataStore.App.Config.FooterHeight}px - ${$dataStore.App.Config.SectionPadding*4}px)` :
                      `height: calc(100vh - 300px - 70px - ${$dataStore.App.Config.FooterHeight - 26}px)`
                    "
                    class="section-body white--text pa-6 overflow-y-auto"
                  >
                    <p>Part of our mission is to ensure that from day 1 our clients are going live with a lean business flow. For this reason, our Transformation office is directly involved in the RFI & RFP stage of any new client proposal.</p>
                    <p>There we offer a high-level pre-diagnosis product, adapted to the specific client’s business ID. By utilizing Lean principals, we evaluate each Clients business capabilities, we document the end-to-end journey of their customers and come back with tailored solutions that can be applied even before onboarding with us.</p>
                    <p>Through end-to-end journey mapping from the end-customers perspective document and visualize Bottlenecks, existing “Wow” moments.</p>
                  </v-card-text>

                  <v-card-actions class="justify-md-start justify-center py-4 px-4">
                    <slide-x-left-transition :delay="200">
                      <cms-button
                        v-show="animateItems"
                        width="270"
                        @click.native="step = 2"
                      >
                        Take a ride and see our unique solutions
                        <template #append>
                          <v-icon right>
                            mdi-chevron-right
                          </v-icon>
                        </template>
                      </cms-button>
                    </slide-x-left-transition>
                  </v-card-actions>
                </v-card>
              </slide-y-up-transition>
            </v-window-item>

            <v-window-item :value="2">
              <v-card
                v-show="animateItems"
                :color="isMobile ? '#E60076' : 'transparent'"
                flat
                tile
              >
                <v-card-text
                  :style="$vuetify.breakpoint.mdAndUp ?
                    `height: calc(100vh - 65px - 66px - ${$dataStore.App.Config.ToolbarHeight}px - ${$dataStore.App.Config.FooterHeight}px - ${$dataStore.App.Config.SectionPadding*4}px)` :
                    `height: calc(100vh - 300px - 60px - ${$dataStore.App.Config.FooterHeight - 26}px)`
                  "
                  class="section-body white--text pa-6 overflow-y-auto"
                >
                  <template v-for="(item, index) in data.Items">
                    <div
                      :key="`menu-item-${index}`"
                      :class="{'selected-menu-item': selectedItem === index}"
                      class="mb-2"
                    >
                      <v-btn
                        depressed
                        large
                        text
                        tile
                        @click="onMenuItemClick(index)"
                      >
                        {{ index + 1 }}. {{ item.Title }}
                      </v-btn>
                    </div>

                    <template v-if="isMobile">
                      <slide-y-up-transition
                        :key="`menu-body-${index}`"
                        :delay="100"
                      >
                        <div
                          v-if="selectedItem === index"
                          class="ml-6"
                          v-html="data.Items[selectedItem].Body"
                        />
                      </slide-y-up-transition>
                    </template>
                  </template>
                </v-card-text>

                <v-card-actions class="justify-md-start justify-center py-4 px-4">
                  <cms-button
                    v-show="animateItems"
                    @click.native="onBackButtonClick"
                  >
                    <template #prepend>
                      <v-icon left>
                        mdi-chevron-left
                      </v-icon>
                    </template>
                    BACK
                  </cms-button>
                </v-card-actions>
              </v-card>
            </v-window-item>
          </v-window>
        </div>
      </v-col>

      <v-col
        v-if="!isMobile"
        class="pt-0 pt-md-3"
        cols="6"
        style="border: 0px red solid;"
      >
        <div :style="`margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight : 0}px; padding: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.SectionPadding : 0}px;`">
          <slide-y-up-transition :delay="100">
            <v-card
              v-if="selectedItem !== null"
              color="transparent"
              flat
              max-width="500"
              tile
            >
              <v-card-text
                :style="$vuetify.breakpoint.mdAndUp ?
                  `height: calc(100vh - 65px - 66px - ${$dataStore.App.Config.ToolbarHeight}px - ${$dataStore.App.Config.FooterHeight}px - ${$dataStore.App.Config.SectionPadding*4}px)` :
                  `height: calc(100vh - 300px - 110px - ${$dataStore.App.Config.FooterHeight - 26}px)`
                "
                class="section-body white--text pa-6 overflow-y-auto"
                style="background-color: rgba(40,52,139,0.8)"
              >
                <div v-html="data.Items[selectedItem].Body" />
              </v-card-text>
            </v-card>
          </slide-y-up-transition>
        </div>
      </v-col>
    </v-row>

    <portal :to="`video-destination-${portalTarget}`">
      <video-background
        :style="$vuetify.breakpoint.mdAndUp ? `position: absolute; height: 100vh; width: 100vw;` : `height: 250px; width: 100%;`"
        poster="/video/value-proposition.jpg"
        src="/video/value-proposition.mp4"
      />
    </portal>
  </v-container>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import CmsButton     from '@/theme/default/components/common/cms-button'

export default {
  name      : 'ValueProposition',
  components: { CmsButton },
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      animateItems: false,
      selectedItem: null,
      step        : 1,
      data        : {
        Body  : '',
        Button: '',
        Items : [
          {
            Title: 'SVA (Smart Video Assistant)',
            Body : '<p>Mobile cellular devices have become a commodity - and one that is highly integrated with our vehicles navigation and entertainment systems. We have developed our own TP solution that allows the end-user to share with the advisor anything they need via their phone’s camera, thus allowing for faster & smoother troubleshooting and call handling.</p>'
          },
          {
            Title: 'storyfAI',
            Body : '<p>Our drivers are as diverse as the world around us – this is why we have developed our own high quality text translation solution that allows us to provide cost-effective support – even for low volume languages.</p>'
          },
          {
            Title: 'Voice & Text Analytics ',
            Body : '<p>Conduct automated quality analysis after interactions, to determine potential defects, leads for sales and retention activities. Every interaction matters and we invest in Voice & Text analytic technologies as to be able to on-demand get insight on any part of our customers journey with customer support.</p>'
          },
          {
            Title: 'TP Unify',
            Body : '<p>A common user interface that can combine and consolidate data from multiple tools. Each car, each model, each B2B or B2C support option will require access to different sources and tools – we aim to simplify this via a unified screen solution that can allow for more efficient and more secure interaction handling.</p>'
          },
          {
            Title: 'Robotic Automation',
            Body : '<p>(RPA, RDA, Optical character recognition): RPA can capture and document the needed information in their behalf offering a truly digitalized way of working.</p>'
          },
          {
            Title: 'TP Simulate',
            Body : '<p>We create any windows-based environment allowing our agents to experience the software exactly as a driver would in their own system.</p>'
          }
        ]
      }
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },

    portalTarget () {
      return this.$vuetify.breakpoint.smAndDown ? 'mobile' : 'desktop'
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.animateItems = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onMenuItemClick (index) {
      const oldSelectedItem = this.selectedItem
      this.selectedItem = null
      if (oldSelectedItem !== index) {
        if (oldSelectedItem === null) {
          this.selectedItem = index
        } else {
          this.$nextTick(() => {
            setTimeout(() => {
              this.selectedItem = index
            }, 250)
          })
        }
      }
    },
    onBackButtonClick () {
      this.selectedItem = null
      this.step = 1
    }
  }
}
</script>

<style scoped>
/deep/ .vue-responsive-videobg .video-buffering {
  background-position : top center;
}

/deep/ .vue-responsive-videobg .video-wrapper video {
  object-fit      : cover;
  object-position : top center;
}

.selected-menu-item {
  display     : flex;
  align-items : center;
}

.selected-menu-item button {
  color        : #FFDE02;
  font-weight  : 900;
  border       : 2px solid;
  border-image : linear-gradient(to right, #FC830A, #BE1CC4 35%, #00AB96) 1;
}

.selected-menu-item:after {
  content       : '';
  flex          : 1;
  margin-left   : 0;
  height        : 1px;
  border-bottom : 2px solid;
  border-image  : linear-gradient(to right, #FC830A, #BE1CC4 35%, #00AB96) 1;
}

</style>
