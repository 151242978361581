<template>
  <v-app>
    <app-navigation-drawer
      :items="navigationDrawerItems"
      :logo-visible="true"
      :navigation-drawer.sync="navigationDrawerVisible"
    />

    <app-toolbar
      :items="toolbarItems"
      :logo-visible="true"
      :navigation-drawer.sync="navigationDrawerVisible"
    />

    <v-main>
      <v-container
        v-resize="onResize"
        align-start
        fill-height
        fluid
      >
        <v-row>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            class="pa-0"
            cols="auto"
          >
            <div :style="`width: ${sidebarWidth}px; height: 100vh;`">
              <v-expand-transition
                mode="out-in"
                origin="center bottom"
              >
                <div
                  v-show="showSidebar"
                  style="width: 100%; height: 100%; background-color: #242424; position: relative;"
                >
                  <div style="width: 50%; height: 100px; background-color: #2C2E33; position: absolute; bottom: 0; left: 25%">
                    <div style="width:100%; height: 8px; background-color: #7A2182; position: absolute; top:0;" />
                  </div>

                  <div class="vertical">
                    <!--
                    <router-link
                      :to="{name: 'Contact'}"
                      active-class="vertical-active"
                      exact
                      exact-active-class="vertical-active"
                    >
                      <span>CONTACT US</span>
                    </router-link>
                    -->
                  </div>
                </div>
              </v-expand-transition>
            </div>
          </v-col>

          <v-col class="pa-0">
            <slot />
          </v-col>
        </v-row>

        <orientation-dialog :visible="orientationDialogVisible" />
      </v-container>

      <app-footer />
    </v-main>
  </v-app>
</template>

<script>
import AppToolbar          from '@/theme/default/components/app/AppToolbar'
import AppNavigationDrawer from '@/theme/default/components/app/AppNavigationDrawer'
import AppFooter           from '@/theme/default/components/app/AppFooter'
import OrientationDialog   from '@/theme/default/components/common/OrientationDialog'

export default {
  name      : 'DefaultLayout',
  components: {
    OrientationDialog,
    AppNavigationDrawer,
    AppToolbar,
    AppFooter
  },
  mixins   : [],
  dataStore: { ScreenOrientation: 'App.Config.ScreenOrientation' },
  data     : function () {
    return {
      showSidebar            : false,
      navigationDrawerVisible: false,
      toolbarItems           : this.$t('Menu.Main'),
      navigationDrawerItems  : this.$t('Menu.Main'),
      windowSize             : { w: 0, h: 0 }
    }
  },
  computed: {
    orientationDialogVisible () {
      return (this.$vuetify.breakpoint.xs || this.windowSize.h <= 500) && (this.ScreenOrientation === 'landscape-primary' || this.ScreenOrientation === 'landscape-secondary')
    },

    sidebarWidth () {
      return this.$dataStore.App.Config.SidebarWidth
    }
  },
  watch: {},
  created () {},
  mounted () {
    this.showSidebar = true
    this.onResize()
  },
  updated () {},
  beforeDestroy () {},
  methods: {
    onResize () {
      this.windowSize = { w: window.innerWidth, h: window.innerHeight }
    }
  }
}

</script>

<style>
.vertical {
  writing-mode      : vertical-rl;
  -webkit-transform : rotate(180deg);
  -moz-transform    : rotate(180deg);
  -o-transform      : rotate(180deg);
  -ms-transform     : rotate(180deg);
  transform         : rotate(180deg);
  white-space       : nowrap;
  position          : absolute;
  display           : block;
  width             : 20px;
  bottom            : 120px;
  left              : calc(50% - 10px);
  font-weight       : bold;
  font-size         : 15px;
}

.vertical a:link {
  text-decoration : none;
  color           : #FFFFFF;
  transition      : color 0.2s;
}

.vertical a:visited {
  text-decoration : none;
  color           : #FFFFFF;
}

.vertical a:hover {
  text-decoration : none;
  color           : #c134ce;
  transition      : color 0.2s;
}

.vertical a:active {
  text-decoration : none;
  color           : #7A2182;
}

.router-link-active, .vertical-active {
  color : #c134ce !important;
}
</style>
