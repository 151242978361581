import Vue                       from 'vue'
import Storage                   from 'vue-web-storage'
import PortalVue                 from 'portal-vue'
import VueMeta                   from 'vue-meta'
import App                       from '@/App'
import vuetify                   from '@/plugins/vuetify'
import { resetRouter, router }   from '@/router'
import { i18n, setI18nLanguage } from '@/lang/lang'
import DataStorePlugin           from '@/lib/data/dataStore/plugin/DataStorePlugin'
import EventBus                  from '@/lib/events/eventBus'
import Enums                     from '@/lib/enums/plugin'
import { themeUtils }            from '@/lib/utils'
import EnvMixin                  from '@/mixins/env'
import LangMiddleware            from '@/router/middleware/LangMiddleware'
import StoreRouteMiddleware      from '@/router/middleware/StoreRouteMiddleware'
import RouteMiddleware           from '@/lib/middleware/RouteMiddleware'
import RouterReadyMiddleware     from '@/router/middleware/RouterReadyMiddleware'
import { setupBugsnag }          from '@/lib/services/Bugsnag'
import DataStore                 from '@/lib/data/dataStore/DataStore'
import VideoBackground           from 'vue-responsive-video-background-player'
import Transitions               from 'vue2-transitions'

import '@mdi/font/css/materialdesignicons.css'
import './App.css'

require('@openfonts/roboto_greek')

const DefaultLayout = () => themeUtils.importThemeComponent('layouts/DefaultLayout')
const ErrorLayout = () => themeUtils.importThemeComponent('layouts/ErrorLayout')

const getSystemConfig = () => {
  /*
  API.Resource.Config.Get()
    .then(response => {
      API.isResponseSuccess(response) && init(response.data)
    })
    .catch(e => {
      // init({})
    })
    .finally(() => { })
   */

  init()
}

const preloadImages = () => {
  preloadImage('/video/home.jpg')
  preloadImage('/img/pages/home/feature-1.png')
  preloadImage('/img/pages/home/feature-2.png')
  preloadImage('/img/pages/home/feature-3.png')
  preloadImage('/video/our-people.jpg')
  preloadImage('/img/pages/services/bg.jpg')
  preloadImage('/img/pages/services/overlay.png')
  preloadImage('/video/success-stories.jpg')
  preloadImage('/video/value-proposition.jpg')
  preloadImage('/img/pages/facilities/bg.jpg')
  preloadImage('/img/pages/csr/bg-1.jpg')
  preloadImage('/img/pages/csr/bg-2.jpg')
  preloadImage('/img/pages/csr/bg-3.jpg')
  preloadImage('/img/pages/contact/bg.jpg')
}

const preloadImage = (image) => {
  const img = new Image()
  img.src = image
}

const initVue = () => {
  resetRouter()

  Vue.component('DefaultLayout', DefaultLayout)
  Vue.component('ErrorLayout', ErrorLayout)
  Vue.component('VideoBackground', VideoBackground)

  Vue.mixin(EnvMixin)

  Vue.use(Transitions)
  Vue.use(EventBus)
  Vue.use(DataStorePlugin)
  Vue.use(Enums)
  Vue.use(PortalVue)
  Vue.use(Storage, {
    prefix : `${ process.env.VUE_APP_STORAGE_PREFIX }_`,
    drivers: ['session', 'local']
  })
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
  })

  window.Vue = new Vue({
    el        : '#app',
    directives: {},
    mixins    : [],
    data () {
      return {}
    },
    beforeMount () {
      setI18nLanguage(process.env.VUE_APP_I18N_LOCALE)
      this.$router.onReady(RouterReadyMiddleware)
      router.beforeEach(RouteMiddleware({ LangMiddleware }, true, this))
      router.afterEach(RouteMiddleware({ StoreRouteMiddleware }, true, this))
    },
    beforeCreate () {},
    created () {},
    mounted () {},
    beforeDestroy () {},
    methods: {},
    i18n,
    router,
    vuetify,
    render : h => h(App)
  })

  if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }
}

const init = (data = {}) => {
  DataStore.App.Config = { ...DataStore?.App?.Config ?? {}, ...data?.data ?? {} }
  preloadImages()
  handleOrientation()
  initVue()
  setupBugsnag()
}

window.addEventListener('orientationchange', handleOrientation)

function handleOrientation () {
  const orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation
  DataStore.App.Config.ScreenOrientation = orientation

  if (orientation === 'landscape-primary') {
    // console.log('landscape-normal')
  } else if (orientation === 'landscape-secondary') {
    // console.log('landscape-upside-down')
  } else if (orientation === 'portrait-secondary' || orientation === 'portrait-primary') {
    // console.log('portrait-normal')
  } else if (orientation === undefined) {
    // console.log('portrait-upside-down')
  }
}

getSystemConfig()
