<template>
  <v-app>
    <v-main style="background-image: url('/img/pages/unauthorized/bg.jpg'); background-position: center center;  background-repeat: no-repeat; ">
      <slot />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name      : 'ErrorLayout',
  components: {},
  mixins    : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods : {}
}

</script>

<style scoped>

</style>
