<template>
  <v-expand-x-transition
    mode="out-in"
    origin="right center"
  >
    <v-footer
      v-show="showFooter"
      :height="footerHeight"
      :width="`${footerWidth}%`"
      class="pa-0"
      color="#242424"
      dark
      fixed
      style="left: auto;"
    >
      <v-row
        class="align-center"
        dense
      >
        <v-col>
          <div class="pl-2 pl-sm-6 footer-title">
            {{ $t(`Route.${ $route.name }.Title`).toLocaleUpperCase($i18n) }}
          </div>
        </v-col>

        <v-col cols="auto">
          <span
            v-if="$route.name === 'Contact'"
            class="pr-2"
            style="font-weight: bold; font-size: 15px; color: #7A2182;"
          >
            info@teleperformance.com
          </span>

          <div
            v-else
            class="d-flex align-center"
          >
            <v-btn
              icon
              @click="prevPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="d-inline-flex pa-1">
              {{ $t(`Route.${ $route.name }.Index`) }} / {{ totalPagesString }}
            </div>
            <v-btn
              icon
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-expand-x-transition>
</template>

<script>

export default {
  name      : 'AppFooter',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  data () {
    return {
      showFooter: false,
      totalPages: 7
    }
  },
  computed: {
    totalPagesString () {
      return String(this.totalPages).padStart(String(this.totalPages).length === 1 ? 2 : String(this.totalPages), '0')
    },

    footerHeight () {
      return this.$dataStore.App.Config.FooterHeight
    },

    footerWidth () {
      return this.$dataStore.App.Config.FooterWidth
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.showFooter = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    nextPage () {
      let idx = this.getCurrentPageIndex()
      if (idx < this.totalPages) {
        idx++
      } else {
        idx = 1
      }

      this.$router.push({ name: this.findPageByIndex(idx).key })
    },

    prevPage () {
      let idx = this.getCurrentPageIndex()
      if (idx > 1) {
        idx--
      } else {
        idx = this.totalPages
      }

      this.$router.push({ name: this.findPageByIndex(idx).key })
    },

    findPageByIndex (idx) {
      const langRouteValues = Object.values(this.$t('Route')) || []
      const langRouteKeys = Object.keys(this.$t('Route')) || []
      const pageIndex = langRouteValues.findIndex(page => idx === parseInt(page.Index))
      const pageKey = langRouteKeys[pageIndex > -1 ? pageIndex : 1]
      return { key: pageKey, page: langRouteValues[pageIndex ?? 1] }
    },

    getCurrentPageIndex () {
      const langRouteValues = Object.values(this.$t('Route')) || []
      const langRouteKeys = Object.keys(this.$t('Route')) || []
      const pageIndex = langRouteKeys.findIndex(page => this.$route.name === page)
      return parseInt(langRouteValues[pageIndex > -1 ? pageIndex : 1].Index)
    }
  }
}
</script>

<style scoped>
.footer-title {
  display     : flex;
  align-items : center;
  font-size   : 18px;
  font-weight : 900;
  font-style  : normal;
  color       : #7A2182;
}

.footer-title:after {
  content          : '';
  flex             : 1;
  margin-left      : 1rem;
  height           : 1px;
  background-color : #7A2182;
}
</style>
