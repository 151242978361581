/**
 * Installation
 *
 * Install and configure in your main file:
 *
 * import Vue from 'vue'
 * import enums from 'vue-enums'
 *
 * // use enums directly in template
 * Vue.use(enums)
 *
 * Alternatively, define enums under a namespace:
 *
 * // in templates, use {{ $enums.YourEnum }}
 * Vue.use(enums, { namespace: '$enums'} )
 *
 * Usage
 * Define enums:
 *
 * const Status = {
 *   ACCEPTED: '1,
 *   DECLINED: 2
 * }
 * export default Status
 *
 * Use in component:
 *
 * <template>
 *   <div v-if="Status.ACCEPTED">
 *    You have been accepted!
 *   </div>
 * </template>
 *
 * <script>
 *   import Status from 'status'
 *
 *   export default {
 *     enums: {
 *       Status,
 *     },

 *     data () {
 *       return {
 *          state: Status.ACCEPTED
 *       }
 *     }
 *   }
 * </script>
 *
 */

export default function Enums (Vue, options = {}) {
  const { namespace } = options

  Vue.config.optionMergeStrategies.enums = (toVal, fromVal) => {
    return {
      ...toVal,
      ...fromVal
    }
  }

  Vue.mixin({
    created () {
      const enums = this.$options.enums
      if (enums) {
        let target = this
        if (namespace) {
          this[namespace] = {}
          target = this[namespace]
        }
        Object.keys(enums).forEach(name => {
          target[name] = Object.freeze(enums[name])
        })
      }
    }
  })
}
