<template>
  <div v-if="hasTitle || hasBody">
    <h1
      v-if="hasTitle"
      :class="titleClass"
    >
      <slot name="title">
        <span v-html="title" />
      </slot>
    </h1>

    <div
      v-if="hasBody"
      :class="[bodyClass, {'pt-6': hasTitle}]"
    >
      <slot name="body">
        <div v-html="body" />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name      : 'CmsTextBlock',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    title: {
      type   : String,
      default: ''
    },
    titleClass: {
      type   : String,
      default: 'section-title'
    },

    Body: {
      type   : String,
      default: ''
    },
    bodyClass: {
      type   : String,
      default: 'section-body'
    }
  },
  enums: {},
  data () {
    return {}
  },
  computed: {
    hasTitle () {
      return !!this.title || !!this.$slots.title
    },

    hasBody () {
      return !!this.body || !!this.$slots.body
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
