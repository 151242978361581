<template>
  <div>
    <v-expand-x-transition
      mode="out-in"
      origin="left center"
    >
      <v-app-bar
        v-show="showToolbar"
        :height="toolbarHeight"
        :max-width="toolbarWidth"
        color="black"
        dark
        dense
        fixed
        flat
        floating
      >
        <div
          v-if="logoVisible"
          class="d-flex align-center fill-height ml-3 mr-3 mt-1"
        >
          <router-link :to="{name: 'Home'}">
            <img
              :height="logoHeight"
              src="/img/logo/logo-light.png"
            >
          </router-link>
        </div>

        <v-spacer />

        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.smAndDown"
          @click.stop="navigationDrawerVisible = !navigationDrawerVisible"
        />

        <v-toolbar-items
          v-if="items && Array.isArray(items) && items.length && $vuetify.breakpoint.mdAndUp"
          class="mx-5"
        >
          <template v-for="(item, i) in items">
            <v-btn
              v-if="item.text"
              :key="`btn-${i}`"
              :exact="item.route.exact"
              :small="$vuetify.breakpoint.mdAndDown"
              :to="{name: item.route.name}"
              :x-small="$vuetify.breakpoint.xsOnly"
              active-class="active-toolbar-item"
              class="px-1"
              plain
            >
              {{ item.text }}
            </v-btn>

            <v-divider
              v-if="item.divider"
              :key="`divider-${i}`"
              vertical
            />
          </template>

          <div style="width: 26px; height: 26px; background-color: #7A2182; position: absolute; right: 0; top: calc(50% - 13px)" />
        </v-toolbar-items>

        <template
          v-if="AppToolbarExtended"
          #extension
        >
          <slot name="extension-prepend" />
          <portal-target
            name="AppToolbarExtension"
            slim
          />
          <slot name="extension-append" />
        </template>
      </v-app-bar>
    </v-expand-x-transition>
  </div>
</template>

<script>

import * as HelperFunctions from '@/lib/helpers'

export default {
  name      : 'AppToolbar',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    items: {
      type    : Array,
      required: true
    },
    navigationDrawer: {
      type   : Boolean,
      default: undefined
    },
    logoVisible: {
      type   : Boolean,
      default: true
    },
    langSwitch: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: { AppToolbarExtended: 'App.Config.AppToolbarExtended' },
  data () {
    return {
      showToolbar: false
    }
  },
  computed: {
    toolbarWidth () {
      if (this.$vuetify.breakpoint.smAndDown) return '100%'
      if (this.$vuetify.breakpoint.mdAndDown) return '80%'
      return '65%'
    },

    toolbarHeight () {
      return this.$dataStore.App.Config.ToolbarHeight
    },

    logoHeight () {
      if (this.$vuetify.breakpoint.xs) return 30
      if (this.$vuetify.breakpoint.mdAndDown) return 35
      return 40
    },

    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.showToolbar = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    ...HelperFunctions
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding-top    : 0;
  padding-bottom : 0;
  height         : 100% !important;
  width          : 100% !important;
}

.active-toolbar-item {
  color   : #c134ce;
  opacity : 1;
}
</style>
