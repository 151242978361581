/* eslint-disable no-sequences */

/**
 * Creates a deep clone of an object.
 *
 * Use recursion. Check if the passed object is null and, if so, return null.
 * Use Object.assign() and an empty object ({}) to create a shallow clone of the original.
 * Use Object.keys() and Array.prototype.forEach() to determine which key-value pairs need to be deep cloned.
 *
 * @example
 * const a = { foo: 'bar', obj: { a: 1, b: 2 } };
 * const b = deepClone(a); // a !== b, a.obj !== b.obj
 *
 * @param obj
 * @return {null|*}
 */
import { sortCompareAlphabetically } from '../array'

export const deepClone = obj => {
  if (obj === null) return null
  const clone = Object.assign({}, obj)
  Object.keys(clone).forEach(
    key => (clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  )
  return Array.isArray(obj) && obj.length
    ? (clone.length = obj.length) && Array.from(clone)
    : Array.isArray(obj)
      ? Array.from(obj)
      : clone
}

export const mergeDeep = (target, ...args) => {
  // deep merge the object into the target object
  const merger = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // if the property is a nested object
          target[prop] = mergeDeep(target[prop], obj[prop])
        } else {
          // for regular property
          target[prop] = obj[prop]
        }
      }
    }
  }

  // iterate through all objects and
  // deep merge them with target
  for (let i = 0; i < args.length; i++) {
    merger(args[i])
  }

  return target
}

/**
 * Deep freezes an object.
 *
 * Calls Object.freeze(obj) recursively on all unfrozen properties of passed object that are instanceof object.
 *
 * @example
 * 'use strict';
 *
 * const o = deepFreeze([1, [2, 3]]);
 *
 * o[0] = 3; // not allowed
 * o[1][0] = 4; // not allowed as well
 *
 *
 * @param obj
 * @return {void | ReadonlyArray<unknown>}
 */
export const deepFreeze = obj => Object.keys(obj).forEach(prop => !(obj[prop] instanceof Object) || Object.isFrozen(obj[prop]) ? null : deepFreeze(obj[prop])) || Object.freeze(obj)

/**
 * Returns the target value in a nested JSON object, based on the keys array.
 *
 * Compare the keys you want in the nested JSON object as an Array.
 * Use Array.prototype.reduce() to get value from nested JSON object one by one.
 * If the key exists in object, return target value, otherwise, return null.
 *
 * @example
 * let index = 2;
 * const data = {
 * foo: {
 *     foz: [1, 2, 3],
 *     bar: {
 *       baz: ['a', 'b', 'c']
 *     }
 * }
 * };
 * deepGet(data, ['foo', 'foz', index]); // get 3
 * deepGet(data, ['foo', 'bar', 'baz', 8, 'foz']); // null
 *
 * @param obj
 * @param keys
 * @return {*}
 */
export const deepGet = (obj, keys) => keys.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj)

/**
 * Deep maps an object's keys.
 *
 * Creates an object with the same values as the provided object and keys generated by running the provided function for each key.
 * Use Object.keys(obj) to iterate over the object's keys.
 * Use Array.prototype.reduce() to create a new object with the same values and mapped keys using fn.
 *
 * @example
 * const obj = {
 *   foo: '1',
 *   nested: {
 *     child: {
 *       withArray: [
 *         {
 *           grandChild: ['hello']
 *         }
 *       ]
 *     }
 *   }
 * };
 *  const upperKeysObj = deepMapKeys(obj, key => key.toUpperCase());
 *
 *  {
 *   "FOO":"1",
 *   "NESTED":{
 *     "CHILD":{
 *       "WITHARRAY":[
 *         {
 *           "GRANDCHILD":[ 'hello' ]
 *         }
 *       ]
 *     }
 *   }
 * }
 *
 * @param obj
 * @param f
 * @return {{}}
 */
export const deepMapKeys = (obj, f) =>
  Array.isArray(obj)
    ? obj.map(val => deepMapKeys(val, f))
    : typeof obj === 'object'
      ? Object.keys(obj).reduce((acc, current) => {
        const val = obj[current]
        acc[f(current)] =
          val !== null && typeof val === 'object' ? deepMapKeys(val, f) : (acc[f(current)] = val)
        return acc
      }, {})
      : obj

/**
 * Assigns default values for all properties in an object that are undefined.
 *
 * Use Object.assign() to create a new empty object and copy the original one to maintain key order,
 * use Array.prototype.reverse() and the spread operator ... to combine the default values from left to right,
 * finally use obj again to overwrite properties that originally had a value.
 *
 * @example defaults({ a: 1 }, { b: 2 }, { b: 6 }, { a: 3 }); // { a: 1, b: 2 }
 *
 * @param obj
 * @param defs
 * @return {any}
 */
export const defaults = (obj, ...defs) => Object.assign({}, obj, ...defs.reverse(), obj)

/**
 * Returns the target value in a nested JSON object, based on the given key.
 *
 * Use the in operator to check if target exists in obj. If found, return the value of obj[target],
 * otherwise use Object.values(obj) and Array.prototype.reduce() to recursively call dig on each
 * nested object until the first matching key/value pair is found.
 *
 * @example
 * const data = {
 *   level1: {
 *     level2: {
 *       level3: 'some data'
 *     }
 *   }
 * };
 * dig(data, 'level3'); // 'some data'
 * dig(data, 'level4'); // undefined
 *
 * @param obj
 * @param target
 * @return {any}
 */
export const dig = (obj, target) =>
  target in obj
    ? obj[target]
    : Object.values(obj).reduce((acc, val) => {
      if (acc !== undefined) return acc
      if (typeof val === 'object') return dig(val, target)
    }, undefined)

/**
 * Returns true if the target value exists in a JSON object, false otherwise.
 *
 * Check if keys is non-empty and use Array.prototype.every() to sequentially check its keys to internal depth
 * of the object, obj. Use Object.prototype.hasOwnProperty() to check if obj does not have the current key or
 * is not an object, stop propagation and return false. Otherwise assign the key's value to obj to use on the next iteration.
 *
 * Return false beforehand if given key list is empty.
 *
 * @example
 * let obj = {
 *   a: 1,
 *   b: { c: 4 },
 *   'b.d': 5
 * };
 * hasKey(obj, ['a']); // true
 * hasKey(obj, ['b']); // true
 * hasKey(obj, ['b', 'c']); // true
 * hasKey(obj, ['b.d']); // true
 * hasKey(obj, ['d']); // false
 * hasKey(obj, ['c']); // false
 * hasKey(obj, ['b', 'f']); // false
 *
 *
 * @param obj
 * @param keys
 * @return {boolean|*}
 */
export const hasKey = (obj, keys) => {
  return (
    keys.length > 0 &&
    keys.every(key => {
      if (typeof obj !== 'object' || !obj.hasOwnProperty(key)) return false
      obj = obj[key]
      return true
    })
  )
}

/**
 * Creates a new object from the specified object, where all the keys are in lowercase.
 *
 * Use Object.keys() and Array.prototype.reduce() to create a new object from the specified object. Convert each key in the original object to lowercase, using String.toLowerCase().
 *
 * @example
 * const myObj = { Name: 'Adam', sUrnAME: 'Smith' };
 * const myObjLower = lowercaseKeys(myObj); // {name: 'Adam', surname: 'Smith'};
 *
 * @param obj
 * @return {{}}
 */
export const lowercaseKeys = obj => Object.keys(obj).reduce((acc, key) => {
  acc[key.toLowerCase()] = obj[key]
  return acc
}, {})

/**
 * Creates an object with keys generated by running the provided function for each key and the same values as the provided object.
 *
 * Use Object.keys(obj) to iterate over the object's keys. Use Array.prototype.reduce() to create a new object with the same values and mapped keys using fn.
 *
 * @example apKeys({ a: 1, b: 2 }, (val, key) => key + val); // { a1: 1, b2: 2 }
 *
 * @param obj
 * @param fn
 * @return {{}}
 */
export const mapKeys = (obj, fn) => Object.keys(obj).reduce((acc, k) => {
  acc[fn(obj[k], k, obj)] = obj[k]
  return acc
}, {})

/**
 * Creates an object with the same keys as the provided object and values generated by running the provided function for each value.
 *
 * Use Object.keys(obj) to iterate over the object's keys. Use Array.prototype.reduce() to create a new object with the same keys and mapped values using fn.
 *
 * @example
 * const users = {
 *   fred: { user: 'fred', age: 40 },
 *   pebbles: { user: 'pebbles', age: 1 }
 * };
 * mapValues(users, u => u.age); // { fred: 40, pebbles: 1 }
 *
 * @param obj
 * @param fn
 * @return {{}}
 */
export const mapValues = (obj, fn) => Object.keys(obj).reduce((acc, k) => {
  acc[k] = fn(obj[k], k, obj)
  return acc
}, {})

/**
 * Creates a new object from the combination of two or more objects.
 *
 * Use Array.prototype.reduce() combined with Object.keys(obj) to iterate over all objects and keys.
 * Use hasOwnProperty() and Array.prototype.concat() to append values for keys existing in multiple objects.
 *
 * @example
 * const object = {
 *   a: [{ x: 2 }, { y: 4 }],
 *   b: 1
 * };
 * const other = {
 *   a: { z: 3 },
 *   b: [2, 3],
 *   c: 'foo'
 * };
 * merge(object, other); // { a: [ { x: 2 }, { y: 4 }, { z: 3 } ], b: [ 1, 2, 3 ], c: 'foo' }
 *
 *
 * @param objs
 * @return {*}
 */
export const merge = (...objs) =>
  [...objs].reduce(
    (acc, obj) =>
      Object.keys(obj).reduce((a, k) => {
        acc[k] = acc.hasOwnProperty(k) ? [].concat(acc[k]).concat(obj[k]) : obj[k]
        return acc
      }, {}),
    {}
  )

/**
 * Given a flat array of objects linked to one another, it will nest them recursively. Useful for nesting comments, such as the ones on reddit.com.
 *
 * Use recursion. Use Array.prototype.filter() to filter the items where the id matches the link, then Array.prototype.map() to map each one to
 * a new object that has a children property which recursively nests the items based on which ones are children of the current item. Omit the second
 * argument, id, to default to null which indicates the object is not linked to another one (i.e. it is a top level object). Omit the third argument,
 * link, to use 'parent_id' as the default property which links the object to another one by its id.
 *
 * @example
 * // One top level comment
 * const comments = [
 *  { id: 1, parent_id: null },
 *  { id: 2, parent_id: 1 },
 *  { id: 3, parent_id: 1 },
 *  { id: 4, parent_id: 2 },
 *  { id: 5, parent_id: 4 }
 * ];
 * const nestedComments = nest(comments); // [{ id: 1, parent_id: null, children: [...] }]
 *
 *
 * @param items
 * @param id
 * @param link
 * @return {*}
 */
export const nest = (items, id = null, link = 'parent_id') => items.filter(item => item[link] === id).map(item => ({
  ...item,
  children: nest(items, item.id)
}))

/**
 * Creates an object from the given key-value pairs.
 *
 * Use Array.prototype.reduce() to create and combine key-value pairs.
 *
 * @example objectFromPairs([['a', 1], ['b', 2]]); // {a: 1, b: 2}
 *
 * @param arr
 * @return {*}
 */
export const objectFromPairs = arr => arr.reduce((a, [key, val]) => ((a[key] = val), a), {})

/**
 * Creates an array of key-value pair arrays from an object.
 *
 * Use Object.keys() and Array.prototype.map() to iterate over the object's keys and produce an array with key-value pairs.
 *
 * @example objectToPairs({ a: 1, b: 2 }); // [ ['a', 1], ['b', 2] ]
 *
 * @param obj
 * @return {[string, *][]}
 */
export const objectToPairs = obj => Object.keys(obj).map(k => [k, obj[k]])

/**
 * Omits the key-value pairs corresponding to the given keys from an object.
 *
 * Use Object.keys(obj), Array.prototype.filter() and Array.prototype.includes() to remove the provided keys.
 * Use Array.prototype.reduce() to convert the filtered keys back to an object with the corresponding key-value pairs.
 *
 * @example omit({ a: 1, b: '2', c: 3 }, ['b']); // { 'a': 1, 'c': 3 }
 *
 * @param obj
 * @param arr
 * @return {{}}
 */
export const omit = (obj, arr) => Object.keys(obj).filter(k => !arr.includes(k)).reduce((acc, key) => ((acc[key] = obj[key]), acc), {})

/**
 * Creates an object composed of the properties the given function returns falsy for. The function is invoked with two arguments: (value, key).
 *
 * Use Object.keys(obj) and Array.prototype.filter()to remove the keys for which fn returns a truthy value. Use Array.prototype.reduce() to
 * convert the filtered keys back to an object with the corresponding key-value pairs.
 *
 * @example omitBy({ a: 1, b: '2', c: 3 }, x => typeof x === 'number'); // { b: '2' }
 *
 * @param obj
 * @param fn
 * @return {{}}
 */
export const omitBy = (obj, fn) => Object.keys(obj).filter(k => !fn(obj[k], k)).reduce((acc, key) => ((acc[key] = obj[key]), acc), {})

export function sortObject (o) {
  return Object.entries(o).sort(([, v1], [, v2]) => +v1 - +v2).reduce((r, [k, v]) => ({
    ...r,
    [k]: v
  }), {})
}

/**
 * Sorts an object by key name
 *
 * @param o
 * @return {{}|*}
 */
export function sortObjectDeepByKey (o) {
  if (typeof o !== 'object' || !o) {
    return o
  }
  // eslint-disable-next-line no-return-assign, no-sequences
  return Object.keys(o).sort(sortCompareAlphabetically('')).reduce((c, key) => (c[key] = sortObjectDeepByKey(o[key]), c), {})
}

/**
 * Sorts an object by key name
 *
 * @param o
 * @return {{}|*}
 */
export function sortObjectByKey (o) {
  if (typeof o !== 'object' || !o) {
    return o
  }
  // eslint-disable-next-line no-return-assign, no-sequences
  return Object.keys(o).sort(sortCompareAlphabetically('')).reduce((c, key) => (c[key] = o[key], c), {})
}

/**
 * Returns a sorted array of objects ordered by properties and orders.
 *
 * Uses Array.prototype.sort(), Array.prototype.reduce() on the props array with a default value of 0,
 * use array destructuring to swap the properties position depending on the order passed. If no orders
 * array is passed it sort by 'asc' by default.
 *
 * @example
 * const users = [{ name: 'fred', age: 48 }, { name: 'barney', age: 36 }, { name: 'fred', age: 40 }];
 * orderBy(users, ['name', 'age'], ['asc', 'desc']); // [{name: 'barney', age: 36}, {name: 'fred', age: 48}, {name: 'fred', age: 40}]
 * orderBy(users, ['name', 'age']); // [{name: 'barney', age: 36}, {name: 'fred', age: 40}, {name: 'fred', age: 48}]
 *
 *
 * @param arr
 * @param props
 * @param orders
 * @return {this}
 */
export const orderBy = (arr, props, orders) =>
  [...arr].sort((a, b) =>
    props.reduce((acc, prop, i) => {
      if (acc === 0) {
        const [p1, p2] = orders && orders[i] === 'desc' ? [b[prop], a[prop]] : [a[prop], b[prop]]
        acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0
      }
      return acc
    }, 0)
  )

/**
 * Picks the key-value pairs corresponding to the given keys from an object.
 *
 * Use Array.prototype.reduce() to convert the filtered/picked keys back to an object with the corresponding key-value pairs if the key exists in the object.
 *
 * @example pick({ a: 1, b: '2', c: 3 }, ['a', 'c']); // { 'a': 1, 'c': 3 }
 *
 * @param obj
 * @param arr
 * @return {*}
 */
export const pick = (obj, arr) => arr.reduce((acc, curr) => (curr in obj && (acc[curr] = obj[curr]), acc), {})

/**
 * Creates an object composed of the properties the given function returns truthy for. The function is invoked with two arguments: (value, key).
 *
 * Use Object.keys(obj) and Array.prototype.filter()to remove the keys for which fn returns a falsy value.
 * Use Array.prototype.reduce() to convert the filtered keys back to an object with the corresponding key-value pairs.
 *
 * @example pickBy({ a: 1, b: '2', c: 3 }, x => typeof x === 'number'); // { 'a': 1, 'c': 3 }
 *
 * @param obj
 * @param fn
 * @return {{}}
 */
export const pickBy = (obj, fn) => Object.keys(obj).filter(k => fn(obj[k], k)).reduce((acc, key) => ((acc[key] = obj[key]), acc), {})

/**
 * Replaces the names of multiple object keys with the values provided.
 *
 * Use Object.keys() in combination with Array.prototype.reduce() and the spread operator (...) to get the object's keys and rename them according to keysMap.
 *
 * @example
 * const obj = { name: 'Bobo', job: 'Front-End Master', shoeSize: 100 };
 * renameKeys({ name: 'firstName', job: 'passion' }, obj); // { firstName: 'Bobo', passion: 'Front-End Master', shoeSize: 100 }
 *
 * @param keysMap
 * @param obj
 * @return {{}}
 */
export const renameKeys = (keysMap, obj) => Object.keys(obj).reduce((acc, key) => ({ ...acc, ...{ [keysMap[key] || key]: obj[key] } }), {})

/**
 * Creates a shallow clone of an object.
 *
 * Use Object.assign() and an empty object ({}) to create a shallow clone of the original.
 *
 * @example
 * const a = { x: true, y: 1 };
 * const b = shallowClone(a); // a !== b
 *
 * @param obj
 * @return {any}
 */
export const shallowClone = obj => Object.assign({}, obj)

/**
 * Get size of arrays, objects or strings.
 *
 * Get type of val (array, object or string). Use length property for arrays.
 * Use length or size value if available or number of keys for objects.
 * Use size of a Blob object created from val for strings.
 * Split strings into array of characters with split('') and return its length.
 *
 * @example size([1, 2, 3, 4, 5]); // 5
 * @example size('size'); // 4
 * @example size({ one: 1, two: 2, three: 3 }); // 3
 *
 * @param val
 * @return {*}
 */
export const size = val =>
  Array.isArray(val)
    ? val.length
    : val && typeof val === 'object'
      ? val.size || val.length || Object.keys(val).length
      : typeof val === 'string'
        ? new Blob([val]).size
        : 0

/**
 * Applies a function against an accumulator and each key in the object (from left to right).
 *
 * Use Object.keys(obj) to iterate over each key in the object, Array.prototype.reduce() to call the apply the specified function against the given accumulator.
 *
 * @example
 * transform(
 *  { a: 1, b: 2, c: 1 },
 *  (r, v, k) => {
 *     (r[v] || (r[v] = [])).push(k);
 *     return r;
 *   },
 *  {}
 *  ); // { '1': ['a', 'c'], '2': ['b'] }
 *
 *
 * @param obj
 * @param fn
 * @param acc
 * @return {string}
 */
export const transform = (obj, fn, acc) => Object.keys(obj).reduce((a, k) => fn(a, obj[k], k, obj), acc)

/**
 * Returns the value of a nested object property from a dot (.) notation string
 *
 * @param path
 * @param data
 * @return {*}
 */
export const pathToValue = (path, data) => {
  let value = null
  if (typeof path === 'string') {
    if (!path.includes('.')) return data[path]
    path = path.split('.')
  }

  try {
    value = path.reduce((pValue, cValue) => {
      return pValue[cValue]
    }, data) || null
  } catch (e) {
    value = null
  }
  return value
}

/**
 * Unflatten an object with the paths for keys.
 *
 * Use Object.keys(obj) combined with Array.prototype.reduce() to convert flattened path node to a leaf node.
 * If the value of a key contains a dot delimiter (.), use Array.prototype.split('.'), string transformations
 * and JSON.parse() to create an object, then Object.assign() to create the leaf node. Otherwise,
 * add the appropriate key-value pair to the accumulator object.
 *
 * @example unflattenObject({ 'a.b.c': 1, d: 1 }); // { a: { b: { c: 1 } }, d: 1 }
 *
 * @param obj
 * @return {{}}
 */
export const unflattenObject = obj =>
  Object.keys(obj).reduce((acc, k) => {
    if (k.indexOf('.') !== -1) {
      const keys = k.split('.')
      Object.assign(
        acc,
        JSON.parse(
          '{' +
          keys.map((v, i) => (i !== keys.length - 1 ? `"${ v }":{` : `"${ v }":`)).join('') +
          obj[k] +
          '}'.repeat(keys.length)
        )
      )
    } else {
      acc[k] = obj[k]
    }
    return acc
  }, {})

/**
 * Iterate over Object or Array with forEach
 *
 * @param obj {Object || Array}
 * @param cb Callback function
 * @returns {Object || Array}
 */
export const forEach = (obj, cb) => {
  let i, len
  if (Array.isArray(obj)) {
    for (i = 0, len = obj.length; i < len; i++) if (cb(obj[i], i, obj) === false) break
  } else {
    for (i in obj) if (cb(obj[i], i, obj) === false) break
  }
  return obj
}

/**
 * Enumerate object properties
 *
 * @type {{getOwnEnumerables: (function(*=): []), getPrototypeEnumerables: (function(*=): []), getOwnAndPrototypeNonenumerables: (function(*=): []), getOwnAndPrototypeEnumerablesAndNonenumerables: (function(*=): []), _notEnumerable: (function(*, *=): boolean), getPrototypeNonenumerables: (function(*=): []), getOwnNonenumerables: (function(*=): []), _getPropertyNames: (function(*=, *=, *=, *): *[]), _enumerableAndNotEnumerable: (function(*, *): boolean), getOwnAndPrototypeEnumerables: (function(*=): []), _enumerable: (function(*, *=): boolean), getOwnEnumerablesAndNonenumerables: (function(*=): []), getPrototypeEnumerablesAndNonenumerables: (function(*=): [])}}
 */
export const SimplePropertyRetriever = {
  getOwnEnumerables: function (obj) {
    return this._getPropertyNames(obj, true, false, this._enumerable)
    // Or could use for..in filtered with hasOwnProperty or just this: return Object.keys(obj);
  },
  getOwnNonenumerables: function (obj) {
    return this._getPropertyNames(obj, true, false, this._notEnumerable)
  },
  getOwnEnumerablesAndNonenumerables: function (obj) {
    return this._getPropertyNames(obj, true, false, this._enumerableAndNotEnumerable)
    // Or just use: return Object.getOwnPropertyNames(obj);
  },
  getPrototypeEnumerables: function (obj) {
    return this._getPropertyNames(obj, false, true, this._enumerable)
  },
  getPrototypeNonenumerables: function (obj) {
    return this._getPropertyNames(obj, false, true, this._notEnumerable)
  },
  getPrototypeEnumerablesAndNonenumerables: function (obj) {
    return this._getPropertyNames(obj, false, true, this._enumerableAndNotEnumerable)
  },
  getOwnAndPrototypeEnumerables: function (obj) {
    return this._getPropertyNames(obj, true, true, this._enumerable)
    // Or could use unfiltered for..in
  },
  getOwnAndPrototypeNonenumerables: function (obj) {
    return this._getPropertyNames(obj, true, true, this._notEnumerable)
  },
  getOwnAndPrototypeEnumerablesAndNonenumerables: function (obj) {
    return this._getPropertyNames(obj, true, true, this._enumerableAndNotEnumerable)
  },
  // Private static property checker callbacks
  _enumerable: function (obj, prop) {
    return obj.propertyIsEnumerable(prop)
  },
  _notEnumerable: function (obj, prop) {
    return !obj.propertyIsEnumerable(prop)
  },
  _enumerableAndNotEnumerable: function (obj, prop) {
    return true
  },
  // Inspired by http://stackoverflow.com/a/8024294/271577
  _getPropertyNames: function getAllPropertyNames (obj, iterateSelfBool, iteratePrototypeBool, includePropCb) {
    const props = []

    do {
      if (iterateSelfBool) {
        Object.getOwnPropertyNames(obj).forEach(function (prop) {
          if (props.indexOf(prop) === -1 && includePropCb(obj, prop)) {
            props.push(prop)
          }
        })
      }
      if (!iteratePrototypeBool) {
        break
      }
      iterateSelfBool = true
      // eslint-disable-next-line no-cond-assign
    } while (obj = Object.getPrototypeOf(obj))

    return props
  }
}
