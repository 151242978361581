import Vue from 'vue'

const VueBus = new Vue()
const Prefix = 'EventBus'
const Separator = '::'
export const EventBus = {
  /**
   * Emit the given event.
   *
   * @param {string} event
   * @param {any} args
   */
  $emit (event, ...args) {
    event = String(Prefix + Separator + event).toUpperCase()
    VueBus.$emit(event, ...args)
  },

  /**
   * Listen for the given event.
   *
   * @param {string} event
   * @param {function} callback
   */
  $on (event, callback) {
    event = String(Prefix + Separator + event).toUpperCase()
    VueBus.$on(event, callback)
  },

  /**
   * Listen for the given event once.
   *
   * @param {string} event
   * @param {function} callback
   */
  $once (event, callback) {
    event = String(Prefix + Separator + event).toUpperCase()
    VueBus.$once(event, callback)
  },

  /**
   * Remove one or more event listeners.
   *
   * @param {string} event
   * @param {function} callback
   */
  $off (event, callback) {
    event = String(Prefix + Separator + event).toUpperCase()
    VueBus.$off(event, callback)
  }
}

const EventBusPlugin = {
  install (Vue, options) {
    // Register a helper prototype property for EventBus access.
    Vue.prototype.$bus = Object.freeze(EventBus)
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(EventBusPlugin)
}

export default EventBusPlugin
