<template>
  <v-container
    fluid
    pa-0
  >
    <v-row class="">
      <v-col cols="12">
        <video-background
          :style="$vuetify.breakpoint.mdAndUp ? `position: absolute; height: 100vh; width: 100vw;` : `height: 250px; width: 100%;`"
          poster="/video/success-stories.jpg"
          src="/video/success-stories.mp4"
        >
          <v-container
            v-if="$vuetify.breakpoint.mdAndUp"
            fluid
            pa-0
          >
            <v-row>
              <v-col cols="6" />
              <v-col
                cols="6"
                style="outline: 0px cyan solid;"
              >
                <div :style="`height: 100vh; width: 100%; position: relative; left: -65px; background-color: #3C3C3C; opacity: 0.75; mix-blend-mode: multiply;`" />
              </v-col>
            </v-row>
          </v-container>
        </video-background>
      </v-col>
    </v-row>

    <v-row
      :style="$vuetify.breakpoint.mdAndUp ? `` : `background-color: #3C3C3C;`"
      class="mt-0 px-3"
      style="isolation: isolate;"
    >
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="6"
        style="outline: 0px red solid;"
      >
        <div :style="`margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight - 15 : 0}px; padding-top: ${$dataStore.App.Config.SectionPadding}px;`">
          <div
            :style="$vuetify.breakpoint.mdAndUp ?
              `padding: ${$dataStore.App.Config.SectionPadding}px; height: calc(100vh - ${$dataStore.App.Config.ToolbarHeight + $dataStore.App.Config.FooterHeight + $dataStore.App.Config.SectionPadding * 2}px);` :
              `padding: ${$dataStore.App.Config.SectionPadding}px; height: calc(100vh - 300px - ${$dataStore.App.Config.FooterHeight - 26}px);`"
            class="overflow-y-auto"
            style="outline: 0px green solid;"
          >
            <div style="outline: 0px orange solid;">
              <cms-text-block>
                <template #title>
                  <slide-x-left-transition :delay="150">
                    <div v-show="animateItems">
                      There is always a<br>story to remember
                    </div>
                  </slide-x-left-transition>
                </template>

                <template #body>
                  <slide-y-up-transition :delay="200">
                    <div
                      v-show="animateItems"
                      class="pa-4 pb-1"
                      style="background-color: #7A2182; opacity: 0.85; max-width: 395px;"
                    >
                      <p>Teleperformance Greece holds a lot of experience with the Automotive Industry. We have been working with various support types coming from automotive since 2015, those include but are not limited to Digital Services, Car Sharing & Technical Support.</p>
                    </div>
                  </slide-y-up-transition>
                </template>
              </cms-text-block>
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <div :style="`outline: 0px green solid; margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight - 15 : 0}px; padding-top: ${$dataStore.App.Config.SectionPadding}px;`">
          <slide-y-up-transition :delay="100">
            <div
              v-show="animateItems"
              class="text-center pa-1"
            >
              <h1
                class="feature-title d-inline-block"
                style="max-width: 350px"
              >
                {{ data.Slider.Title }}
              </h1>
            </div>
          </slide-y-up-transition>

          <slide-x-left-transition :delay="200">
            <v-window
              v-show="animateItems"
              v-model="step"
              show-arrows
            >
              <template v-for="(item, index) in data.Slider.Items.slice($vuetify.breakpoint.mdAndUp ? 1 : 0)">
                <v-window-item :key="`slide-${index}`">
                  <v-card
                    :style="$vuetify.breakpoint.mdAndUp ?
                      `height: calc(100vh - ${$dataStore.App.Config.ToolbarHeight}px - ${$dataStore.App.Config.FooterHeight}px - ${$dataStore.App.Config.SectionPadding*4}px)` :
                      `height: calc(100vh - 340px - ${$dataStore.App.Config.FooterHeight - 26}px)`
                    "
                    class="overflow-y-auto overflow-x-hidden"
                    color="transparent"
                    flat
                  >
                    <v-row
                      align="center"
                      class="fill-height"
                      justify="center"
                    >
                      <v-card-text class="text-center px-14">
                        <h2
                          v-if="item.Title"
                          class="section-title d-inline-block"
                          style="max-width: 500px"
                          v-html="item.Title"
                        />

                        <div class="section-body ma-6">
                          <v-img
                            v-if="item.Image"
                            :height="$vuetify.breakpoint.mdAndUp ? 120 : 80"
                            :src="item.Image"
                            contain
                          />
                        </div>

                        <div
                          v-if="item.Body"
                          class="section-body d-inline-block"
                          style="max-width: 500px"
                          v-html="item.Body"
                        />
                      </v-card-text>
                    </v-row>
                  </v-card>
                </v-window-item>
              </template>
            </v-window>
          </slide-x-left-transition>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import CmsTextBlock  from '@/theme/default/components/cms/CmsTextBlock'

export default {
  name      : 'SuccessStories',
  components: { CmsTextBlock },
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      animateItems: false,
      step        : 0,
      data        : {
        Slider: {
          Title: 'Throughout this experience we find today ourselves hosting 667 Employees out of which:',
          Items: [
            {
              Title: 'There is always a<br>story to remember',
              Image: '',
              Body : 'Teleperformance Greece holds a lot of experience with the Automotive Industry. We have been working with various support types coming from automotive since 2015, those include but are not limited to Digital Services, Car Sharing & Technical Support.'
            },
            {
              Title: '593 Agents',
              Image: '/img/pages/success-stories/slide-1.png',
              Body : ''
            },
            {
              Title: '24 Languages',
              Image: '/img/pages/success-stories/slide-2.png',
              Body : 'Bulgarian, Czech, Danish, Dutch, English, Estonian, Finnish, French, German, Greek, Hungarian, Italian, Latvian, Lithuanian, Norwegian, Polish, Portuguese, Romanian, Serbian, Slovenian, Spanish, Swedish, Ukrainian, Flemish'
            },
            {
              Title: '4 Channels',
              Image: '/img/pages/success-stories/slide-3.png',
              Body : 'Voice, Chat, E-mail, Social Media'
            },
            {
              Title: '9 Type of Interactions',
              Image: '/img/pages/success-stories/slide-4.png',
              Body : ' App Troubleshooting, Electromobility, Complaints, Registration, Application Usage, Invoicing, Warranties and Contracts, Blocked Accounts, Rentals, Roadside Assistance'
            },
            {
              Title: '2 Main Support Categories: Products & Services.',
              Image: '/img/pages/success-stories/slide-5.png',
              Body : 'Products & Services. Examples: Digital Services, Electromobility / Charging, Fleet Management, Car rentals / sharing, Dealer Support, Sales, Classic Vehicle Support, Payments, Logistics, Marketing Campaigns.'
            }
          ]
        }
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.animateItems = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .vue-responsive-videobg .video-buffering {
  background-position : top center;
}

/deep/ .vue-responsive-videobg .video-wrapper video {
  object-fit      : cover;
  object-position : top center;
}

@media screen and (max-width : 959px) {
  /deep/ .v-window__next, /deep/ .v-window__prev {
    margin : 0 0;
  }
}
</style>
