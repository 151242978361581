<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <v-img
          class="mb-16"
          contain
          height="55"
          src="/img/logo/logo-light.png"
        />
      </v-col>

      <v-col
        class="text-center section-title"
        cols="12"
      >
        <div class="my-16">
          <v-icon size="64">
            mdi-close
          </v-icon>
          <p>Oops it seems you don't have access to view this page</p>
        </div>
      </v-col>

      <v-col
        class="text-center"
        cols="12"
      >
        <cms-button
          href="https://teleperformance.com/"
          target="_blank"
        >
          CLICK HERE FOR MORE
        </cms-button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CmsButton from '@/theme/default/components/common/cms-button'

export default {
  name      : 'UnAuthorized',
  components: { CmsButton },
  directives: {},
  mixins    : [],
  props     : {},
  enums     : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
</style>
