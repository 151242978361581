<template>
  <v-dialog
    v-model="isVisible"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    class="elevation-0"
    hide-overlay
    max-width="960px"
    persistent
    scrollable
  >
    <v-card
      class="elevation-0"
      color="transparent"
      flat
      min-width="320"
      tile
    >
      <v-toolbar
        color="rgba(40,52,139,0.75)"
        dark
        flat
        height="120"
        tile
      >
        <v-spacer />
        <v-btn
          icon
          x-large
          @click="closeDialog"
        >
          <v-icon x-large>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="background-color: #00ab96">
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <cms-text-block class="pt-6">
                <template #body>
                  <div class="black--text">
                    <p>
                      <strong>- Dealership Visits for our Agents:</strong> New hires visit the dealerships where, they get to understand the connection between the dealership and the client, the services they provide and the experience the customers go through.
                    </p>
                    <p>
                      <strong>- In Car Visual Simulators:</strong> Visual simulators are placed within training & Production allowing advisors to get the customer experience. Simulators increase customer satisfaction, reduce average handling time & increase learning curve effectiveness.
                    </p>
                    <p>
                      <strong>- Work from Home & Cloud Campus</strong>
                    </p>
                    <p>
                      <strong>- On Prem Test Drivers for our agents:</strong> Build a firsthand experience with the car.
                    </p>
                  </div>
                </template>
              </cms-text-block>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <cms-text-block>
                <template #title>
                  <h2 class="feature-item-title white--text text-center mt-6">
                    {{ data.Title }}
                  </h2>
                </template>

                <template #body>
                  <div class="text-center">
                    <div
                      class="d-inline-block"
                      style="max-width: 500px"
                    >
                      <template v-for="item in data.Items">
                        <v-avatar
                          :key="item"
                          class="pa-1 ma-3"
                          size="120"
                          style="border: 1px solid #FFFFFF"
                        >
                          <div
                            class="pa-2 white--text"
                            style="font-size: 16px;"
                          >
                            {{ item }}
                          </div>
                        </v-avatar>
                      </template>
                    </div>
                  </div>
                </template>
              </cms-text-block>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogVisible from '@/mixins/dialog/dialogVisible'
import CmsTextBlock  from '@/theme/default/components/cms/CmsTextBlock'

export default {
  name      : 'SpecialTrainingApproachDialog',
  components: { CmsTextBlock },
  directives: {},
  mixins    : [dialogVisible],
  props     : {},
  enums     : {},
  data () {
    return {
      data: {
        Title: 'Advanced Cloud Campus facilities which provide a numerous benefit to our trainees.',
        Items: ['Sound - proof broadcasting rooms', 'Covid - safe facilities', 'Robotic 4K camera', 'Interactive board', 'Service video wall', 'Dedicated TTT Session for trainers']
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
/deep/ .v-dialog {
  border-radius : 0;
  box-shadow    : initial;
}
</style>
