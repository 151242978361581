<template>
  <v-container
    align-start
    fill-height
    fluid
    pa-0
  >
    <portal-target name="video-destination-desktop" />

    <v-row v-if="isMobile">
      <v-col cols="12">
        <portal-target name="video-destination-mobile" />
      </v-col>
    </v-row>

    <v-row
      class="mt-0 px-sm-0 px-md-3"
      style="isolation: isolate;"
    >
      <v-col
        class="pt-0 pt-md-3"
        cols="12"
        md="6"
        style="border: 0px red solid;"
      >
        <div :style="`margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight : 0}px; padding: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.SectionPadding : 0}px;`">
          <v-window
            v-model="step"
            :show-arrows="isMobile"
            class="overflow-visible"
            dark
          >
            <v-window-item :value="1">
              <portal-target name="win1-destination" />
            </v-window-item>

            <v-window-item :value="2">
              <portal-target name="win2-destination-mobile" />
            </v-window-item>
          </v-window>
        </div>
      </v-col>

      <v-col
        v-if="!isMobile"
        cols="12"
        md="6"
        style="border: 0px red solid;"
      >
        <div :style="`margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight : 0}px; padding: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.SectionPadding : 0}px;`">
          <portal-target name="win2-destination-desktop" />
        </div>
      </v-col>

      <v-col
        v-if="!isMobile"
        class="text-center ma-0 pa-0"
        cols="12"
      >
        <slide-x-left-transition :delay="300">
          <cms-button
            v-show="animateItems"
            href="https://tpgreece.teleperformance.gr/tour/teleperformancegreece"
            target="_blank"
          >
            STEP IN TO OUR DIGITAL SPACE
            <template #append>
              <v-icon right>
                mdi-chevron-right
              </v-icon>
            </template>
          </cms-button>
        </slide-x-left-transition>
      </v-col>
    </v-row>

    <portal :to="`video-destination-${portalTarget}`">
      <video-background
        :style="$vuetify.breakpoint.mdAndUp ? `position: absolute; height: 100vh; width: 100vw;` : `height: 250px; width: 100%;`"
        poster="/img/pages/facilities/bg.jpg"
        src=""
      />
    </portal>

    <portal to="win1-destination">
      <slide-y-up-transition :delay="100">
        <v-card
          v-show="animateItems"
          color="rgba(40,52,139,0.8)"
          flat
          tile
        >
          <v-card-title class="section-title pl-14">
            Facilities
          </v-card-title>

          <v-card-text
            :style="$vuetify.breakpoint.mdAndUp ?
              `height: calc(100vh - 65px - 66px - ${$dataStore.App.Config.ToolbarHeight}px - ${$dataStore.App.Config.FooterHeight}px - ${$dataStore.App.Config.SectionPadding*4}px)` :
              `height: calc(100vh - 300px - 110px - ${$dataStore.App.Config.FooterHeight - 26}px)`
            "
            class="section-body overflow-y-auto"
          >
            <ol class="circle-list facilities">
              <li>
                <p>Pioneers in Infrastructure:</p>
                <ul>
                  <li>Our premises follow all health & safety regulations</li>
                  <li>They have a 24/7 security coverage.</li>
                  <li>Dedicated customized experience areas to host any necessary activity or equipment</li>
                </ul>
              </li>

              <li>
                <p>Cloud campus virtual facilities:</p>
                <ul>
                  <li>It provides a centralized command center with the highest standards for excellence</li>
                  <li>Digital transformation that transcends local markets' limits.</li>
                  <li>Turning Greece into a strategic partner of choice</li>
                </ul>
              </li>

              <li>
                <p>Design a Lean Warehouse for accessories CRP (consumable replaceable parts) following the world class standards.</p>
                <ul>
                  <li>Structured information flows</li>
                  <li>Robust system interconnections & live monitoring mechanism</li>
                </ul>
              </li>
            </ol>
          </v-card-text>

          <v-card-actions
            v-if="isMobile"
            class="justify-center py-4"
          >
            <slide-x-left-transition :delay="300">
              <cms-button
                v-show="animateItems"
                href="https://tpgreece.teleperformance.gr/tour/teleperformancegreece"
                target="_blank"
              >
                STEP IN TO OUR DIGITAL SPACE
                <template #append>
                  <v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </template>
              </cms-button>
            </slide-x-left-transition>
          </v-card-actions>
        </v-card>
      </slide-y-up-transition>
    </portal>

    <portal :to="`win2-destination-${portalTarget}`">
      <slide-y-up-transition :delay="200">
        <v-card
          v-show="animateItems"
          color="rgba(122,33,130,0.8)"
          flat
          tile
        >
          <v-card-title class="section-title pl-14">
            Security
          </v-card-title>

          <v-card-text
            :style="$vuetify.breakpoint.mdAndUp ?
              `height: calc(100vh - 65px - 66px - ${$dataStore.App.Config.ToolbarHeight}px - ${$dataStore.App.Config.FooterHeight}px - ${$dataStore.App.Config.SectionPadding*4}px)` :
              `height: calc(100vh - 300px - 110px - ${$dataStore.App.Config.FooterHeight - 26}px)`
            "
            class="section-body overflow-y-auto"
          >
            <ol class="circle-list security">
              <li><p>TP’s Disaster Recovery Plan includes a set of policies, systems, and processes to guarantee the Business Continuity.</p></li>
              <li><p>Multiple security related certifications, have been acquired and acknowledge our high and non-negotiable standards</p></li>
              <li>
                <p>The automotive industry is at a crossroads where the vehicle itself and the consumer’s experience with it are rapidly shifting from the physical to digital . To further enhance this commitment, we have developed an additional internal security software (TP Protect) that is deployed on all internal devices. This offers:</p>
                <ul>
                  <li>Real-time customer success specialist desktop activity</li>
                  <li>Triggering alerts for attempted actions</li>
                  <li>Real-time system alerts for risk elements and suspicious patterns</li>
                  <li>PII data protection</li>
                  <li>Interactive contact center blueprint and reporting on call status</li>
                </ul>
              </li>
              <li><p>IT support 24/7, ensure immediate resolution and accurate troubleshooting.</p></li>
              <li><p>We have deployed an Agile scrum operating model allowing for quick wins and retrospective actions.</p></li>
              <li><p>IT Research & Development is integrated in the core daily cycle as a set of strategic, proactive, catalytic, and capacity-building activity.</p></li>
            </ol>
          </v-card-text>

          <v-card-actions
            v-if="isMobile"
            class="justify-center py-4"
          >
            <slide-x-left-transition :delay="300">
              <cms-button
                v-show="animateItems"
                href="https://tpgreece.teleperformance.gr/tour/teleperformancegreece"
                target="_blank"
              >
                STEP IN TO OUR DIGITAL SPACE
                <template #append>
                  <v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </template>
              </cms-button>
            </slide-x-left-transition>
          </v-card-actions>
        </v-card>
      </slide-y-up-transition>
    </portal>
  </v-container>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import CmsButton     from '@/theme/default/components/common/cms-button'

export default {
  name      : 'Facilities',
  components: { CmsButton },
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      formSuccess : false,
      animateItems: false,
      step        : 1
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },

    portalTarget () {
      return this.$vuetify.breakpoint.smAndDown ? 'mobile' : 'desktop'
    }
  },
  watch: {
    portalTarget () {
      this.step = 1
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.animateItems = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .vue-responsive-videobg .video-buffering {
  background-position : top center;
}

/deep/ .vue-responsive-videobg .video-wrapper video {
  object-fit      : cover;
  object-position : top center;
}

ol.circle-list > li > ul {
  list-style-type : disc;
  margin-left     : 40px;
  margin-top      : 16px;
}

ol.circle-list {
  list-style    : none;
  counter-reset : item;
  padding       : 0;
  color         : white;
}

ol.circle-list > li {
  counter-increment : item;
  margin-bottom     : 26px;
}

ol.circle-list > li > p {
  margin-top  : -30px;
  margin-left : 40px;
}

ol.circle-list > li:before {
  margin-right  : 10px;
  content       : counter(item, upper-alpha);
  background    : #FF8A65;
  border-radius : 100%;
  color         : white;
  width         : 30px;
  height        : 30px;
  font-size     : 25px;
  line-height   : 30px;
  text-align    : center;
  display       : inline-block;
}

ol.circle-list.facilities > li:before {
  background : #00AB96;
}

ol.circle-list.security > li:before {
  background : #E60076;
}

/deep/ .v-window__next, /deep/ .v-window__prev {
  margin : 0 -12px;
}
</style>
