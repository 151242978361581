<template>
  <v-container
    fill-height
    fluid
    pa-0
  >
    <video-background
      poster="/img/pages/contact/bg.jpg"
      src=""
      style="position: absolute; height: 100vh; width: 100vw; z-index: 0"
    />

    <v-row
      class="mt-0 px-3"
      style="isolation: isolate;"
    >
      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="12"
        style="border: 0px red solid;"
      >
        <div :style="`height: calc(100vh - 32px - ${$dataStore.App.Config.ToolbarHeight}px);`">
          <slide-y-up-transition :delay="150">
            <div
              v-show="animateItems"
              :style="`margin-top: ${$dataStore.App.Config.ToolbarHeight}px; padding: ${$dataStore.App.Config.SectionPadding}px;`"
            >
              <div style="border: 0px green solid; background-color: #E60076;">
                <div
                  :style="`height: 465px;`"
                  class="overflow-y-auto px-2"
                  style="border: 0px black solid;"
                >
                  <fade-transition>
                    <div
                      v-if="formSuccess"
                      class="text-center"
                      style="position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%)"
                    >
                      <v-icon size="64">
                        mdi-check
                      </v-icon>
                      <h2>Your application has been submitted successfully</h2>
                    </div>
                  </fade-transition>

                  <div
                    v-if="!formSuccess"
                    class="pa-3"
                    style="border: 0px orange solid;"
                  >
                    <!--
                    <form id="mktoForm_1443" />
                    -->

                    <v-text-field
                      color="white"
                      label="First Name"
                    />

                    <v-text-field
                      color="white"
                      label="Last Name"
                    />

                    <v-text-field
                      color="white"
                      label="Company"
                    />

                    <v-text-field
                      color="white"
                      label="Job Title"
                    />

                    <v-text-field
                      color="white"
                      label="Country"
                    />

                    <v-text-field
                      color="white"
                      label="Company Email"
                    />
                  </div>
                </div>

                <div class="py-4 text-center">
                  <slide-x-left-transition>
                    <cms-button
                      v-if="!formSuccess"
                      v-show="animateItems"
                      @click.native="onSubmitClick"
                    >
                      SUBMIT
                    </cms-button>
                  </slide-x-left-transition>
                </div>
              </div>
            </div>
          </slide-y-up-transition>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import CmsButton     from '@/theme/default/components/common/cms-button'

export default {
  name      : 'Contact',
  components: { CmsButton },
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      formSuccess : false,
      animateItems: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    // window.MktoForms2.loadForm('//app-sjp.marketo.com', '195-OTS-308', 1443)
  },
  beforeMount () {},
  mounted () {
    this.animateItems = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onSubmitClick () {
      this.formSuccess = !this.formSuccess
    }
  }
}
</script>

<style scoped>
/deep/ .vue-responsive-videobg .video-buffering {
  background-position : top center;
}

/deep/ .vue-responsive-videobg .video-wrapper video {
  object-fit      : cover;
  object-position : top center;
}

.VideoBg {
  position            : relative;
  background-size     : cover;
  background-position : center;
  overflow            : hidden;
}

.VideoBg video {
  position   : absolute;
  top        : 50%;
  left       : 50%;
  visibility : hidden;
  transform  : translate(-50%, -50%);
}

.VideoBg__content {
  position : absolute;
  top      : 0;
  left     : 0;
  width    : 100%;
  height   : 100%;
}
</style>
