/**
 * Escapes a string for use in HTML.
 *
 * Use String.prototype.replace() with a regexp that matches the characters that need to be escaped,
 * using a callback function to replace each character instance with its associated escaped character
 * using a dictionary (object).
 *
 * @example escapeHTML('<a href="#">Me & you</a>'); // '&lt;a href=&quot;#&quot;&gt;Me &amp; you&lt;/a&gt;'
 *
 * @param str
 * @returns {*}
 */

export function escapeHTML2 (str) {
  return str.replace(/[&<>'"]/g, tag => ({
    '&' : '&amp;',
    '<' : '&lt;',
    '>' : '&gt;',
    '\'': '&#39;',
    '"' : '&quot;'
  }[tag] || tag))
}

export const escapeHTML = str => str.replace(/[&<>'"]/g, tag => ({
  '&' : '&amp;',
  '<' : '&lt;',
  '>' : '&gt;',
  '\'': '&#39;',
  '"' : '&quot;'
}[tag] || tag))

/**
 * Unescapes escaped HTML characters.
 *
 * Use String.prototype.replace() with a regex that matches the characters that need to be unescaped, using a callback function to
 * replace each escaped character instance with its associated unescaped character using a dictionary (object).
 *
 * @example unescapeHTML('&lt;a href=&quot;#&quot;&gt;Me &amp; you&lt;/a&gt;'); // '<a href="#">Me & you</a>'
 *
 * @param str
 * @returns {*}
 */
export const unescapeHTML = str => str.replace(/&amp;|&lt;|&gt;|&#39;|&quot;/g, tag => ({
  '&amp;' : '&',
  '&lt;'  : '<',
  '&gt;'  : '>',
  '&#39;' : '\'',
  '&quot;': '"'
}[tag] || tag))

/**
 * Removes HTML/XML tags from string.
 *
 * Use a regular expression to remove HTML/XML tags from a string.
 *
 * @example stripHTMLTags('<p><em>lorem</em> <strong>ipsum</strong></p>'); // 'lorem ipsum'
 *
 * @param str
 * @returns {*}
 */
export const stripHTMLTags = str => str.replace(/<[^>]*>/g, '')

/**
 * Checks if a string is lower case.
 * Convert the given string to lower case, using String.prototype.toLowerCase() and compare it to the original.
 *
 * @param str
 * @returns {boolean}
 */
export const isLowerCase = str => str === str.toLowerCase()

/**
 * Checks if a string is upper case.
 * Convert the given string to upper case, using String.prototype.toUpperCase() and compare it to the original.
 *
 * @param str
 * @returns {boolean}
 */
export const isUpperCase = str => str === str.toUpperCase()

/**
 * Replaces all but the last num of characters with the specified mask character.
 *
 * Use String.prototype.slice() to grab the portion of the characters that will remain unmasked and use
 * String.padStart() to fill the beginning of the string with the mask character up to the original length.
 * Omit the second argument, num, to keep a default of 4 characters unmasked. If num is negative, the unmasked
 * characters will be at the start of the string. Omit the third argument, mask, to use a default character of '*' for the mask.
 *
 * @example mask(1234567890); // '******7890'
 * @example mask(1234567890, 3); // '*******890'
 * @example mask(1234567890, -4, '$'); // '$$$$567890'
 *
 * @param cc
 * @param num
 * @param mask
 * @returns {string}
 */
export const mask = (cc, num = 4, mask = '*') => `${ cc }`.slice(-num).padStart(`${ cc }`.length, mask)

/**
 * Pads a string on both sides with the specified character, if it's shorter than the specified length.
 *
 * Use String.padStart() and String.padEnd() to pad both sides of the given string. Omit the third argument, char,
 * to use the whitespace character as the default padding character.
 *
 * @example pad('cat', 8); // '  cat   '
 * @example pad(String(42), 6, '0'); // '004200'
 * @example pad('foobar', 3); // 'foobar'
 *
 * @param str
 * @param length
 * @param char
 * @returns {string}
 */
export const pad = (str, length, char = ' ') => str.padStart((str.length + length) / 2, char).padEnd(length, char)

/**
 * Removes non-printable ASCII characters.
 *
 * Use a regular expression to remove non-printable ASCII characters.
 *
 * @example removeNonASCII('äÄçÇéÉêlorem-ipsumöÖÐþúÚ'); // 'lorem-ipsum'
 *
 * @param str
 * @returns {*}
 */
export const removeNonASCII = str => str.replace(/[^\x20-\x7E]/g, '')

/**
 * Reverses a string.
 *
 * Use the spread operator (...) and Array.prototype.reverse() to reverse the order of the characters in the string.
 * Combine characters to get a string using String.prototype.join('').
 *
 * @example reverseString('foobar'); // 'raboof'
 *
 * @param str
 * @returns {string}
 */
export const reverseString = str => [...str].reverse().join('')

/**
 * Splits a multiline string into an array of lines.
 *
 * Use String.prototype.split() and a regular expression to match line breaks and create an array.
 *
 * @example splitLines('This\nis a\nmultiline\nstring.\n'); // ['This', 'is a', 'multiline', 'string.' , '']
 *
 * @param str
 * @returns {[parser.Node[], parser.Node[]] | string[] | *}
 */
export const splitLines = str => str.split(/\r?\n/)

/**
 * Converts a string from camelcase.
 *
 * Use String.prototype.replace() to remove underscores, hyphens, and spaces and convert words to camelcase.
 * Omit the second argument to use a default separator of _.
 *
 * @example fromCamelCase('someDatabaseFieldName', ' '); // 'some database field name'
 * @example fromCamelCase('someLabelThatNeedsToBeCamelized', '-'); // 'some-label-that-needs-to-be-camelized'
 * @example fromCamelCase('someJavascriptProperty', '_'); // 'some_javascript_property'
 *
 * @param str
 * @param separator
 * @returns {string}
 */
export const fromCamelCase = (str, separator = '_') => str.replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2').replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2').toLowerCase()

/**
 * Converts a string to camelcase.
 *
 * Break the string into words and combine them capitalizing the first letter of each word, using a regexp.
 *
 * @example toCamelCase('some_database_field_name'); // 'someDatabaseFieldName'
 * @example toCamelCase('Some label that needs to be camelized'); // 'someLabelThatNeedsToBeCamelized'
 * @example toCamelCase('some-javascript-property'); // 'someJavascriptProperty'
 * @example toCamelCase('some-mixed_string with spaces_underscores-and-hyphens'); // 'someMixedStringWithSpacesUnderscoresAndHyphens'
 *
 * @param str
 * @returns {string}
 */
export const toCamelCase = str => {
  const s = str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase()).join('')
  return s.slice(0, 1).toLowerCase() + s.slice(1)
}

/**
 * Converts a string to PascalCase.
 *
 * Break the string into words and combine them capitalizing the first letter of each word, using a regexp.
 *
 * @example toPascalCase('some_database_field_name'); // 'SomeDatabaseFieldName'
 * @example toPascalCase('Some label that needs to be camelized'); // 'SomeLabelThatNeedsToBeCamelized'
 * @example toPascalCase('some-javascript-property'); // 'SomeJavascriptProperty'
 * @example toPascalCase('some-mixed_string with spaces_underscores-and-hyphens'); // 'SomeMixedStringWithSpacesUnderscoresAndHyphens'
 *
 * @param str
 * @returns {string}
 */
export const toPascalCase = str => {
  return str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase()).join('')
}

/**
 * Converts a string to kebab case.
 *
 * Break the string into words and combine them adding - as a separator, using a regexp.
 *
 * @example toKebabCase('camelCase'); // 'camel-case'
 * @example toKebabCase('some text'); // 'some-text'
 * @example toKebabCase('some-mixed_string With spaces_underscores-and-hyphens'); // 'some-mixed-string-with-spaces-underscores-and-hyphens'
 * @example toKebabCase('AllThe-small Things'); // "all-the-small-things"
 * @example toKebabCase('IAmListeningToFMWhileLoadingDifferentURLOnMyBrowserAndAlsoEditingSomeXMLAndHTML'); // "i-am-listening-to-fm-while-loading-different-url-on-my-browser-and-also-editing-xml-and-html"
 *
 * @param str
 * @returns {*}
 */
export const toKebabCase = str => str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('-')

/**
 * Converts a string to snake case.
 *
 * Break the string into words and combine them adding _ as a separator, using a regexp.
 *
 * @example toSnakeCase('camelCase'); // 'camel_case'
 * @example toSnakeCase('some text'); // 'some_text'
 * @example toSnakeCase('some-mixed_string With spaces_underscores-and-hyphens'); // 'some_mixed_string_with_spaces_underscores_and_hyphens'
 * @example toSnakeCase('AllThe-small Things'); // "all_the_smal_things"
 * @example toSnakeCase('IAmListeningToFMWhileLoadingDifferentURLOnMyBrowserAndAlsoEditingSomeXMLAndHTML'); // "i_am_listening_to_fm_while_loading_different_url_on_my_browser_and_also_editing_some_xml_and_html"
 *
 * @param str
 * @returns {*}
 */
export const toSnakeCase = str => str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('_')

/**
 * Converts a string to title case.
 *
 * Break the string into words, using a regexp, and combine them capitalizing the first letter of each word and adding a whitespace between them.
 *
 * @example toTitleCase('some_database_field_name'); // 'Some Database Field Name'
 * @example toTitleCase('Some label that needs to be title-cased'); // 'Some Label That Needs To Be Title Cased'
 * @example toTitleCase('some-package-name'); // 'Some Package Name'
 * @example toTitleCase('some-mixed_string with spaces_underscores-and-hyphens'); // 'Some Mixed String With Spaces Underscores And Hyphens'
 *
 * @param str
 * @returns {*}
 */
export const toTitleCase = str => str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ')

/**
 * Truncates a string up to a specified length.
 *
 * Determine if the string's length is greater than num. Return the string truncated to the desired length, with '...' appended to the end or the original string.
 *
 * @example truncateString('boomerang', 7); // 'boom...'
 *
 * @param str
 * @param num
 * @returns {string}
 */
export const truncateString = (str, num) => str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + '...' : str
export { truncateString as truncate }

/**
 * Joins all given URL segments together, then normalizes the resulting URL.
 *
 * Use String.prototype.join('/') to combine URL segments, then a series of String.prototype.replace() calls with
 * various regexps to normalize the resulting URL (remove double slashes, add proper slashes for protocol,
 * remove slashes before parameters, combine parameters with '&' and normalize first parameter delimiter).
 *
 * @example URLJoin('http://www.google.com', 'a', '/b/cd', '?foo=123', '?bar=foo'); // 'http://www.google.com/a/b/cd?foo=123&bar=foo'
 *
 * @param args
 * @returns {*}
 * @constructor
 */
// eslint-disable-next-line no-useless-escape
export const URLJoin = (...args) => args.join('/').replace(/[\/]+/g, '/').replace(/^(.+):\//, '$1://').replace(/^file:/, 'file:/').replace(/\/(\?|&|#[^!])/g, '$1').replace(/\?/g, '&').replace('&', '?')

/**
 * Converts a given string into an array of words.
 *
 * Use String.prototype.split() with a supplied pattern (defaults to non-alpha as a regexp) to convert to an array of strings.
 * Use Array.prototype.filter() to remove any empty strings. Omit the second argument to use the default regexp.
 *
 * @example words('I love javaScript!!'); // ["I", "love", "javaScript"]
 * @example words('python, javaScript & coffee'); // ["python", "javaScript", "coffee"]
 *
 * @param str
 * @param pattern
 * @returns {T[]}
 */
export const words = (str, pattern = /[^a-zA-Z-]+/) => str.split(pattern).filter(Boolean)

/**
 * Converts a string into Capitalize
 *
 * @param value
 * @returns {string}
 */
export const capitalize = value => (value || value === 0) ? String(value).charAt(0).toUpperCase() + String(value).slice(1) : ''

/**
 * Converts a string into UpperCase
 *
 * @param value
 * @returns {string}
 */
export const uppercase = value => (value || value === 0) ? String(value).toUpperCase() : ''

/**
 * Converts a string to lowercase
 *
 * @param value
 * @returns {string}
 */
export const lowercase = value => (value || value === 0) ? String(value).toLowerCase() : ''

/**
 * If the value is missing outputs the placeholder text
 *
 * @example '' => {placeholder}
 * @example 'foo' => 'foo'
 *
 * @param input
 * @param property
 * @returns {*}
 */
export const placeholder = (input, property) => (input === undefined || input === '' || input === null) ? property : input

/**
 * Returns the plural of an English word.
 *
 * @export
 * @param {string} word
 * @param {number} [amount]
 * @returns {string}
 */
export const plural = (word, amount) => {
  if (amount !== undefined && amount === 1) {
    return word
  }
  const plural = {
    '(quiz)$'                    : '$1zes',
    '^(ox)$'                     : '$1en',
    '([m|l])ouse$'               : '$1ice',
    '(matr|vert|ind)ix|ex$'      : '$1ices',
    '(x|ch|ss|sh)$'              : '$1es',
    '([^aeiouy]|qu)y$'           : '$1ies',
    '(hive)$'                    : '$1s',
    '(?:([^f])fe|([lr])f)$'      : '$1$2ves',
    '(shea|lea|loa|thie)f$'      : '$1ves',
    sis$                         : 'ses',
    '([ti])um$'                  : '$1a',
    '(tomat|potat|ech|her|vet)o$': '$1oes',
    '(bu)s$'                     : '$1ses',
    '(alias)$'                   : '$1es',
    '(octop)us$'                 : '$1i',
    '(ax|test)is$'               : '$1es',
    '(us)$'                      : '$1es',
    '([^s]+)$'                   : '$1s'
  }
  const irregular = {
    move  : 'moves',
    foot  : 'feet',
    goose : 'geese',
    sex   : 'sexes',
    child : 'children',
    man   : 'men',
    tooth : 'teeth',
    person: 'people'
  }
  const uncountable = [
    'sheep',
    'fish',
    'deer',
    'moose',
    'series',
    'species',
    'money',
    'rice',
    'information',
    'equipment',
    'bison',
    'cod',
    'offspring',
    'pike',
    'salmon',
    'shrimp',
    'swine',
    'trout',
    'aircraft',
    'hovercraft',
    'spacecraft',
    'sugar',
    'tuna',
    'you',
    'wood'
  ]
  // save some time in the case that singular and plural are the same
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
    return word
  }
  // check for irregular forms
  for (const w in irregular) {
    const pattern = new RegExp(`${ w }$`, 'i')
    const replace = irregular[w]
    if (pattern.test(word)) {
      return word.replace(pattern, replace)
    }
  }
  // check for matches using regular expressions
  for (const reg in plural) {
    const pattern = new RegExp(reg, 'i')
    if (pattern.test(word)) {
      return word.replace(pattern, plural[reg])
    }
  }
  return word
}

/**
 * Returns the singular of an English word.
 *
 * @export
 * @param {string} word
 * @param {number} [amount]
 * @returns {string}
 */
export const singular = (word, amount) => {
  if (amount !== undefined && amount !== 1) {
    return word
  }
  const singular = {
    '(quiz)zes$'                                                   : '$1',
    '(matr)ices$'                                                  : '$1ix',
    '(vert|ind)ices$'                                              : '$1ex',
    '^(ox)en$'                                                     : '$1',
    '(alias)es$'                                                   : '$1',
    '(octop|vir)i$'                                                : '$1us',
    '(cris|ax|test)es$'                                            : '$1is',
    '(shoe)s$'                                                     : '$1',
    '(o)es$'                                                       : '$1',
    '(bus)es$'                                                     : '$1',
    '([m|l])ice$'                                                  : '$1ouse',
    '(x|ch|ss|sh)es$'                                              : '$1',
    '(m)ovies$'                                                    : '$1ovie',
    '(s)eries$'                                                    : '$1eries',
    '([^aeiouy]|qu)ies$'                                           : '$1y',
    '([lr])ves$'                                                   : '$1f',
    '(tive)s$'                                                     : '$1',
    '(hive)s$'                                                     : '$1',
    '(li|wi|kni)ves$'                                              : '$1fe',
    '(shea|loa|lea|thie)ves$'                                      : '$1f',
    '(^analy)ses$'                                                 : '$1sis',
    '((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$': '$1$2sis',
    '([ti])a$'                                                     : '$1um',
    '(n)ews$'                                                      : '$1ews',
    '(h|bl)ouses$'                                                 : '$1ouse',
    '(corpse)s$'                                                   : '$1',
    '(us)es$'                                                      : '$1',
    s$                                                             : ''
  }
  const irregular = {
    move  : 'moves',
    foot  : 'feet',
    goose : 'geese',
    sex   : 'sexes',
    child : 'children',
    man   : 'men',
    tooth : 'teeth',
    person: 'people'
  }
  const uncountable = [
    'sheep',
    'fish',
    'deer',
    'moose',
    'series',
    'species',
    'money',
    'rice',
    'information',
    'equipment',
    'bison',
    'cod',
    'offspring',
    'pike',
    'salmon',
    'shrimp',
    'swine',
    'trout',
    'aircraft',
    'hovercraft',
    'spacecraft',
    'sugar',
    'tuna',
    'you',
    'wood'
  ]
  // save some time in the case that singular and plural are the same
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
    return word
  }
  // check for irregular forms
  for (const w in irregular) {
    const pattern = new RegExp(`${ irregular[w] }$`, 'i')
    const replace = w
    if (pattern.test(word)) {
      return word.replace(pattern, replace)
    }
  }
  // check for matches using regular expressions
  for (const reg in singular) {
    const pattern = new RegExp(reg, 'i')
    if (pattern.test(word)) {
      return word.replace(pattern, singular[reg])
    }
  }
  return word
}
