export default {

  App: {
    Title: 'Teleperformance Automotive',
    Desc : '',
    Img  : ''
  },

  Date: {
    Title     : 'Ημερομηνία | Ημερομηνίες',
    Today     : 'Σήμερα',
    Day       : 'Ημέρα | Ημέρες',
    Week      : 'Εβδομάδα | Εβδομάδες',
    Month     : 'Μήνας | Μήνες',
    Year      : 'Έτος | Έτη',
    Period    : 'Περίοδος | Περίοδοι',
    PeriodFrom: 'Από',
    PeriodTo  : 'Έως',
    Expire    : 'Λήξη'
  },

  Button: {
    All     : 'Όλα',
    Save    : 'Αποθήκευση',
    Update  : 'Ενημέρωση',
    Copy    : 'Αντιγραφή',
    View    : 'Προβολή',
    ViewAll : 'Προβολή Όλων',
    New     : 'Δημιουργία',
    Create  : 'Δημιουργία',
    Continue: 'Συνέχεια',
    Edit    : 'Επεξεργασία',
    Export  : 'Εξαγωγή',
    Import  : 'Εισαγωγή',
    Delete  : 'Διαγραφή',
    Remove  : 'Διαγραφή',
    Reset   : 'Επαναφορά',
    Login   : 'Σύνδεση',
    Logout  : 'Αποσύνδεση',
    Cancel  : 'Ακύρωση',
    Close   : 'Κλείσιμο',
    Send    : 'Αποστολή',
    Yes     : 'Ναι',
    No      : 'Όχι',
    Ok      : 'Οκ',
    Next    : 'Επόμενο',
    Prev    : 'Προηγούμενο'
  },

  Error: {
    Generic    : 'Υπήρξε κάποιο πρόβλημα. Παρακαλώ προσπαθήστε ξανά.',
    Staging    : 'Προσοχή! Αυτό είναι DEMO περιβάλλον.',
    Development: 'Προσοχή! Αυτό είναι DEV περιβάλλον.'
  },

  Misc: {
    From     : 'Από',
    To       : 'Σε',
    Locale   : 'Γλώσσα',
    CreatedAt: '@:Common.Button.Create',
    UpdatedAt: '@:Common.Button.Update',
    DeletedAt: '@:Common.Button.Delete'
  }
}
