import { themeUtils } from '@/lib/utils'
import { i18n }       from '@/lang/lang'

// eslint-disable-next-line no-unused-vars
const PlaceHolder = () => themeUtils.importThemeComponent('components/common/PlaceHolder')
const UnAuthorized = () => themeUtils.importThemeComponent('components/common/UnAuthorized')
const Home = () => themeUtils.importThemeComponent('views/home/Home')
const OurPeople = () => themeUtils.importThemeComponent('views/our-people/OurPeople')
const Services = () => themeUtils.importThemeComponent('views/services/Services')
const SuccessStories = () => themeUtils.importThemeComponent('views/success-stories/SuccessStories')
const ValueProposition = () => themeUtils.importThemeComponent('views/value-proposition/ValueProposition')
const Facilities = () => themeUtils.importThemeComponent('views/facilities/Facilities')
const Csr = () => themeUtils.importThemeComponent('views/csr/Csr')
// const Contact = () => themeUtils.importThemeComponent('views/contact/Contact')

export const AppRoutes = {
  SimpleRoutes: [
    {
      name    : 'Default',
      path    : '*',
      redirect: '/' + i18n.locale
    }
  ],

  LangRoutes: [
    {
      name     : 'Home',
      path     : '',
      component: Home,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },

    {
      name     : 'OurPeople',
      path     : 'our-people',
      component: OurPeople,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },

    {
      name     : 'Services',
      path     : 'services',
      component: Services,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },

    {
      name     : 'SuccessStories',
      path     : 'success-stories',
      component: SuccessStories,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },

    {
      name     : 'ValueProposition',
      path     : 'value-proposition',
      component: ValueProposition,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },

    {
      name     : 'Facilities',
      path     : 'facilities',
      component: Facilities,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },

    {
      name     : 'Csr',
      path     : 'csr',
      component: Csr,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },
    /*
    {
      name     : 'Contact',
      path     : 'contact',
      component: Contact,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },
    */
    {
      name     : 'Unauthorized',
      path     : 'unauthorized',
      component: UnAuthorized,
      meta     : {
        requiresAuth: false,
        layout      : 'Error'
      }
    }
  ]
}

export default AppRoutes
