export default {
  Main: [
    {
      text : 'Our People',
      route: {
        name : 'OurPeople',
        exact: true
      }
    },
    {
      text : 'Services',
      route: {
        name : 'Services',
        exact: true
      }
    },
    {
      text : 'Success Stories',
      route: {
        name : 'SuccessStories',
        exact: true
      }
    },
    {
      text : 'Value Proposition',
      route: {
        name : 'ValueProposition',
        exact: true
      }
    },
    {
      text : 'Facilities',
      route: {
        name : 'Facilities',
        exact: true
      }
    },
    {
      text : 'CSR',
      route: {
        name : 'Csr',
        exact: true
      }
    }
  ]
}
