/* eslint-disable no-sequences */

/**
 * Returns true if the provided predicate function returns true for all elements in a collection, false otherwise.
 *
 * Use Array.prototype.every() to test if all elements in the collection return true based on fn.
 * Omit the second argument, fn, to use Boolean as a default.
 *
 * @example all([4, 2, 3], x => x > 1); // true
 * @example all([1, 2, 3]); // true
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
import { isArray }     from '../type'
import { pathToValue } from '../object'

export const all = (arr, fn = Boolean) => arr.every(fn)

/**
 * Check if all elements in an array are equal.
 *
 * Use Array.prototype.every() to check if all the elements of the array are the same as the first one.
 * Elements in the array are compared using the strict comparison operator, which does not account for NaN self-inequality.
 *
 * @example allEqual([1, 2, 3, 4, 5, 6]); // false
 * @example allEqual([1, 1, 1, 1]); // true
 *
 * @param arr
 * @returns {*}
 */
export const allEqual = arr => arr.every(val => val === arr[0])

/**
 * Returns true if the provided predicate function returns true for at least one element in a collection, false otherwise.
 *
 * Use Array.prototype.some() to test if any elements in the collection return true based on fn. Omit the second argument, fn,
 * to use Boolean as a default.
 *
 * @example any([0, 1, 2, 0], x => x >= 2); // true
 * @example any([0, 0, 1, 0]); // true
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
export const any = (arr, fn = Boolean) => arr.some(fn)

/**
 * Removes falsy values from an array.
 *
 * Use Array.prototype.filter() to filter out falsy values (false, null, 0, "", undefined, and NaN).
 *
 * @example compact([0, 1, false, 2, '', 3, 'a', 'e' * 23, NaN, 's', 34]); // [ 1, 2, 3, 'a', 's', 34 ]
 *
 * @param arr
 * @returns {*}
 */
export const compact = arr => arr.filter(Boolean)
export { compact as filterFalsy }

/**
 * Create a new array with items based on BaseModel
 *
 * @param Payload
 * @param Model || Resource
 * @returns {*[]}
 */
export const collect = (Payload = [], Model) => {
  if (!isArray(Payload)) return []

  if (Model && Payload && isArray(Payload) && Payload.length > 0) {
    Payload.forEach((item, index) => {
      Payload[index] = new Model(item)
    })
  }

  return Payload
}

/**
 * Casts the provided value as an array if it's not one.
 *
 * Use Array.prototype.isArray() to determine if val is an array and return it as-is or encapsulated in an array accordingly.
 *
 * @example castToArray('foo'); // ['foo']
 * @example castToArray([1]); // [1]
 *
 * @param val
 * @returns {*[]}
 */
export const castToArray = val => (Array.isArray(val) ? val : [val])

/**
 * Chunks an array into smaller arrays of a specified size.
 *
 * Use Array.from() to create a new array, that fits the number of chunks that will be produced.
 * Use Array.prototype.slice() to map each element of the new array to a chunk the length of size.
 * If the original array can't be split evenly, the final chunk will contain the remaining elements.
 *
 * @example chunk([1, 2, 3, 4, 5], 2); // [[1,2],[3,4],[5]]
 *
 * @param arr
 * @param size
 * @returns {*[]}
 */
export const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))

/**
 * Counts the occurrences of a value in an array.
 *
 * Use Array.prototype.reduce() to increment a counter each time you encounter the specific value inside the array.
 *
 * @example countOccurrences([1, 1, 2, 1, 2, 3], 1); // 3
 *
 * @param arr
 * @param val
 * @returns {*}
 */
export const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0)

/**
 * Groups the elements of an array based on the given function and returns the count of elements in each group.
 *
 * Use Array.prototype.map() to map the values of an array to a function or property name.
 * Use Array.prototype.reduce() to create an object, where the keys are produced from the mapped results.
 *
 * @example countBy([6.1, 4.2, 6.3], Math.floor); // {4: 1, 6: 2}
 * @example countBy(['one', 'two', 'three'], 'length'); // {3: 2, 5: 1}
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
export const countBy = (arr, fn) => arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val) => {
  acc[val] = (acc[val] || 0) + 1
  return acc
}, {})

// export const

/**
 * Returns the difference between two arrays.
 *
 * Create a Set from b, then use Array.prototype.filter() on a to only keep values not contained in b.
 *
 * @example difference([1, 2, 3], [1, 2, 4]); // [3]
 *
 * @param a
 * @param b
 * @returns {*}
 */
export const difference = (a, b) => {
  const s = new Set(b)
  return a.filter(x => !s.has(x))
}

/**
 * Returns the difference between two arrays, after applying the provided function to each array element of both.
 *
 * Create a Set by applying fn to each element in b, then use Array.prototype.map() to apply fn to each element in a, then Array.prototype.filter()
 *
 * @example differenceBy([2.1, 1.2], [2.3, 3.4], Math.floor); // [1]
 * @example differenceBy([{ x: 2 }, { x: 1 }], [{ x: 1 }], v => v.x); // [2]
 *
 * @param a
 * @param b
 * @param fn
 * @returns {*}
 */
export const differenceBy = (a, b, fn) => {
  const s = new Set(b.map(fn))
  return a.map(fn).filter(el => !s.has(el))
}

/**
 * Filters out all values from an array for which the comparator function does not return true.
 *
 * Use Array.prototype.filter() and Array.prototype.findIndex() to find the appropriate values.
 *
 * @example differenceWith([1, 1.2, 1.5, 3, 0], [1.9, 3, 0], (a, b) => Math.round(a) === Math.round(b)); // [1, 1.2]
 *
 * @param arr
 * @param val
 * @param comp
 * @returns {*}
 */
export const differenceWith = (arr, val, comp) => arr.filter(a => val.findIndex(b => comp(a, b)) === -1)

/**
 * Returns a new array with n elements removed from the left.
 *
 * Use Array.prototype.slice() to remove the specified number of elements from the left.
 *
 * @example drop([1, 2, 3]); // [2,3]
 * @example drop([1, 2, 3], 2); // [3]
 * @example drop([1, 2, 3], 42); // []
 *
 * @param arr
 * @param n
 * @returns {*}
 */
export const drop = (arr, n = 1) => arr.slice(n)

/**
 * Returns a new array with n elements removed from the right.
 *
 * Use Array.prototype.slice() to remove the specified number of elements from the right.
 *
 * @example dropRight([1, 2, 3]); // [1,2]
 * @example dropRight([1, 2, 3], 2); // [1]
 * @example dropRight([1, 2, 3], 42); // []
 *
 * @param arr
 * @param n
 * @returns {*}
 */
export const dropRight = (arr, n = 1) => arr.slice(0, -n)

/**
 * Removes elements from the end of an array until the passed function returns true. Returns the remaining elements in the array.
 *
 * Loop through the array, using Array.prototype.slice() to drop the last element of the array until the returned value from the function is true.
 * Returns the remaining elements.
 *
 * @example dropRightWhile([1, 2, 3, 4], n => n < 3); // [1, 2]
 *
 * @param arr
 * @param func
 * @returns {*}
 */
export const dropRightWhile = (arr, func) => {
  let rightIndex = arr.length
  while (rightIndex-- && !func(arr[rightIndex])) {
    return arr.slice(0, rightIndex + 1)
  }
}

/**
 * Removes elements in an array until the passed function returns true. Returns the remaining elements in the array.
 *
 * Loop through the array, using Array.prototype.slice() to drop the first element of the array until the returned value from the function is true.
 * Returns the remaining elements.
 *
 * @example dropWhile([1, 2, 3, 4], n => n >= 3); // [3,4]
 *
 * @param arr
 * @param func
 * @returns {*}
 */
export const dropWhile = (arr, func) => {
  while (arr.length > 0 && !func(arr[0])) arr = arr.slice(1)
  return arr
}

/**
 * Returns every nth element in an array.
 *
 * Use Array.prototype.filter() to create a new array that contains every nth element of a given array.
 *
 * @example everyNth([1, 2, 3, 4, 5, 6], 2); // [ 2, 4, 6 ]
 *
 * @param arr
 * @param nth
 * @returns {*}
 */
export const everyNth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1)

/**
 * Filters out the non-unique values in an array.
 *
 * Use Array.prototype.filter() for an array containing only the unique values.
 *
 * @example filterNonUnique([1, 2, 2, 3, 4, 4, 5]); // [1, 3, 5]
 *
 * @param arr
 * @returns {*}
 */
export const filterNonUnique = arr => arr.filter(i => arr.indexOf(i) === arr.lastIndexOf(i))

/**
 * Filters out the non-unique values in an array, based on a provided comparator function.
 *
 * Use Array.prototype.filter() and Array.prototype.every() for an array containing only the unique values, based on the comparator function, fn.
 * The comparator function takes four arguments: the values of the two elements being compared and their indexes.
 *
 * @example filterNonUniqueBy(
 * [
 * { id: 0, value: 'a' },
 * { id: 1, value: 'b' },
 * { id: 2, value: 'c' },
 * { id: 1, value: 'd' },
 * { id: 0, value: 'e' }
 * ],
 * (a, b) => a.id == b.id
 * ); // [ { id: 2, value: 'c' } ]
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
export const filterNonUniqueBy = (arr, fn) => arr.filter((v, i) => arr.every((x, j) => (i === j) === fn(v, x, i, j)))

/**
 * Returns an array with unique objects based on prop
 *
 * @param {Array} array
 * @param {String} prop
 * @return {Array}
 */
export function uniqueArray (array, prop = 'Id') {
  return array.reduce((unique, item) => {
    return unique.find(t => prop ? t[prop] === item[prop] : t === item) ? unique : [...unique, item]
  }, [])
}

/**
 * Flattens an array up to the specified depth.
 *
 * Use recursion, decrementing depth by 1 for each level of depth. Use Array.prototype.reduce() and Array.prototype.concat() to merge elements or arrays.
 * Base case, for depth equal to 1 stops recursion. Omit the second argument, depth to flatten only to a depth of 1 (single flatten).
 *
 * @example flatten([1, [2], 3, 4]); // [1, 2, 3, 4]
 * @example flatten([1, [2, [3, [4, 5], 6], 7], 8], 2); // [1, 2, 3, [4, 5], 6, 7, 8]
 *
 * @param arr
 * @param depth
 * @returns {*}
 */
export const flatten = (arr, depth = 1) => arr.reduce((a, v) => a.concat(depth > 1 && Array.isArray(v) ? flatten(v, depth - 1) : v), [])

/**
 * Groups the elements of an array based on the given function.
 *
 * Use Array.prototype.map() to map the values of an array to a function or property name.
 * Use Array.prototype.reduce() to create an object, where the keys are produced from the mapped results.
 *
 * @example groupBy([6.1, 4.2, 6.3], Math.floor); // {4: [4.2], 6: [6.1, 6.3]}
 * @example groupBy(['one', 'two', 'three'], 'length'); // {3: ['one', 'two'], 5: ['three']}
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
export const groupBy = (arr, fn) => arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val, i) => {
  acc[val] = (acc[val] || []).concat(arr[i])
  return acc
}, {})

/**
 * Returns true if all the elements ιν values are included in arr, false otherwise.
 *
 * Use Array.prototype.every() and Array.prototype.includes() to check if all elements of values are included in arr.
 *
 * @example includesAll([1, 2, 3, 4], [1, 4]); // true
 * @example includesAll([1, 2, 3, 4], [1, 5]); // false
 *
 * @param arr
 * @param values
 * @returns {*}
 */
export const includesAll = (arr, values) => values.every(v => arr.includes(v))

/**
 * Returns true if at least one element of values is included in arr , false otherwise.
 *
 * Use Array.prototype.some() and Array.prototype.includes() to check if at least one element of values is included in arr.
 *
 * @example includesAny([1, 2, 3, 4], [2, 9]); // true
 * @example includesAny([1, 2, 3, 4], [8, 9]); // false
 *
 * @param arr
 * @param values
 * @returns {*}
 */
export const includesAny = (arr, values) => values.some(v => arr.includes(v))

/**
 * Returns the first element in an array.
 *
 * Use arr[0] to get the first element of the given array and returning it.
 *
 * @example last([1, 2, 3]); // 3
 *
 * @param arr
 * @returns {*}
 */
export const first = arr => arr[0]

/**
 * Returns the last element in an array.
 *
 * Use arr.length - 1 to compute the index of the last element of the given array and returning it.
 *
 * @example last([1, 2, 3]); // 3
 *
 * @param arr
 * @returns {*}
 */
export const last = arr => arr[arr.length - 1]

/**
 * Groups the elements into two arrays, depending on the provided function's truthiness for each element.
 *
 * Use Array.prototype.reduce() to create an array of two arrays. Use Array.prototype.push() to add elements for
 * which fn returns true to the first array and elements for which fn returns false to the second one.
 *
 * @example
 * const users = [{ user: 'barney', age: 36, active: false }, { user: 'fred', age: 40, active: true }];
 * partition(users, o => o.active); // [[{ 'user': 'fred',    'age': 40, 'active': true }],[{ 'user': 'barney',  'age': 36, 'active': false }]]
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
export const partition = (arr, fn) => arr.reduce((acc, val, i, arr) => {
  acc[fn(val, i, arr) ? 0 : 1].push(val)
  return acc
}, [[], []])

/**
 * Filters an array's values based on a predicate function, returning only values for which the predicate function returns true.
 *
 * Use Array.prototype.filter() in combination with the predicate function, pred, to return only the values for which pred() returns true.
 *
 * @example reject(x => x % 2 === 0, [1, 2, 3, 4, 5]); // [1, 3, 5]
 * @example reject(word => word.length > 4, ['Apple', 'Pear', 'Kiwi', 'Banana']); // ['Pear', 'Kiwi']
 *
 * @param pred
 * @param array
 * @returns {*}
 */
export const reject = (pred, array) => array.filter((...args) => !pred(...args))

/**
 * Removes elements from an array for which the given function returns false.
 *
 * Use Array.prototype.filter() to find array elements that return truthy values and Array.prototype.reduce() to
 * remove elements using Array.prototype.splice(). The func is invoked with three arguments (value, index, array).
 *
 * @example remove([1, 2, 3, 4], n => n % 2 === 0); // [2, 4]
 *
 * @param arr
 * @param func
 * @returns {Array}
 */
export const remove = (arr, func) => Array.isArray(arr) ? arr.filter(func).reduce((acc, val) => {
  arr.splice(arr.indexOf(val), 1)
  return acc.concat(val)
}, []) : []

/**
 * Randomizes the order of the values of an array, returning a new array.
 *
 * Uses the Fisher-Yates algorithm to reorder the elements of the array.
 *
 * @example const foo = [1, 2, 3];
 * shuffle(foo); // [2, 3, 1], foo = [1, 2, 3]
 *
 * @param arr
 * @returns {*}
 */
export const shuffle = ([...arr]) => {
  let m = arr.length
  while (m) {
    const i = Math.floor(Math.random() * m--);
    [arr[m], arr[i]] = [arr[i], arr[m]]
  }
  return arr
}

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 *
 * @example MyData.sort(sortCompareAlphabetically("name"));
 */
export const sortCompareAlphabetically = (property) => {
  let sortOrder = 1

  if (property && property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return property ? pathToValue(property, b).localeCompare(pathToValue(property, a)) : b.localeCompare(a)
    } else {
      return property ? pathToValue(property, a).localeCompare(pathToValue(property, b)) : a.localeCompare(b)
    }
  }
}

/**
 * Returns an array with n elements removed from the beginning.
 *
 * Use Array.prototype.slice() to create a slice of the array with n elements taken from the beginning.
 *
 * @example take([1, 2, 3], 5); // [1, 2, 3]
 * @example take([1, 2, 3], 0); // []
 *
 * @param arr
 * @param n
 * @returns {*}
 */
export const take = (arr, n = 1) => arr.slice(0, n)

/**
 * Returns an array with n elements removed from the end.
 *
 * Use Array.prototype.slice() to create a slice of the array with n elements taken from the end.
 *
 * @example takeRight([1, 2, 3], 2); // [ 2, 3 ]
 * @example takeRight([1, 2, 3]); // [3]
 *
 * @param arr
 * @param n
 * @returns {*}
 */
export const takeRight = (arr, n = 1) => arr.slice(arr.length - n, arr.length)

/**
 * Removes elements from the end of an array until the passed function returns true. Returns the removed elements.
 *
 * Loop through the array, using a Array.prototype.reduceRight() and accumulating elements while the function returns falsy value.
 *
 * @example takeRightWhile([1, 2, 3, 4], n => n < 3); // [3, 4]
 *
 * @param arr
 * @param func
 * @returns {*}
 */
export const takeRightWhile = (arr, func) => arr.reduceRight((acc, el) => (func(el) ? acc : [el, ...acc]), [])

/**
 * Removes elements in an array until the passed function returns true. Returns the removed elements.
 *
 * Loop through the array, using a for...of loop over Array.prototype.entries() until the returned value
 * from the function is true. Return the removed elements, using Array.prototype.slice().
 *
 * @example takeWhile([1, 2, 3, 4], n => n >= 3); // [1, 2]
 *
 * @param arr
 * @param func
 * @returns {*}
 */
export const takeWhile = (arr, func) => {
  for (const [i, val] of arr.entries()) if (func(val)) return arr.slice(0, i)
  return arr
}

/**
 * Returns every element that exists in any of the two arrays once.
 *
 * Create a Set with all values of a and b and convert to an array.
 *
 * @example union([1, 2, 3], [4, 3, 2]); // [1,2,3,4]
 *
 * @param a
 * @param b
 * @returns {*[]}
 */
export const union = (a, b) => Array.from(new Set([...a, ...b]))

/**
 * Returns every element that exists in any of the two arrays once, after applying the provided function to each array element of both.
 *
 * Create a Set by applying all fn to all values of a. Create a Set from a and all elements in b whose value, after applying fn does
 * not match a value in the previously created set. Return the last set converted to an array.
 *
 * @example unionBy([2.1], [1.2, 2.3], Math.floor); // [2.1, 1.2]
 *
 * @param a
 * @param b
 * @param fn
 * @returns {*[]}
 */
export const unionBy = (a, b, fn) => {
  const s = new Set(a.map(fn))
  return Array.from(new Set([...a, ...b.filter(x => !s.has(fn(x)))]))
}

/**
 * Returns every element that exists in any of the two arrays once, using a provided comparator function.
 *
 * Create a Set with all values of a and values in b for which the comparator finds no matches in a, using Array.prototype.findIndex().
 *
 * @example unionWith([1, 1.2, 1.5, 3, 0], [1.9, 3, 0, 3.9], (a, b) => Math.round(a) === Math.round(b)); // [1, 1.2, 1.5, 3, 0, 3.9]
 *
 * @param a
 * @param b
 * @param comp
 * @returns {*[]}
 */
export const unionWith = (a, b, comp) => Array.from(new Set([...a, ...b.filter(x => a.findIndex(y => comp(x, y)) === -1)]))

/**
 * Returns all unique values of an array.
 *
 * Use ES6 Set and the ...rest operator to discard all duplicated values.
 *
 * @example uniqueElements([1, 2, 2, 3, 4, 4, 5]); // [1, 2, 3, 4, 5]
 *
 * @param arr
 * @returns {any[]}
 */
export const uniqueElements = arr => [...new Set(arr)]

/**
 * Returns all unique values of an array, based on a provided comparator function.
 *
 * Use Array.prototype.reduce() and Array.prototype.some() for an array containing only the first unique occurrence of each value,
 * based on the comparator function, fn. The comparator function takes two arguments: the values of the two elements being compared.
 *
 * @example uniqueElementsBy(
 * [
 * { id: 0, value: 'a' },
 * { id: 1, value: 'b' },
 * { id: 2, value: 'c' },
 * { id: 1, value: 'd' },
 * { id: 0, value: 'e' }
 * ],
 * (a, b) => a.id == b.id
 * ); // [ { id: 0, value: 'a' }, { id: 1, value: 'b' }, { id: 2, value: 'c' } ]
 *
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
export const uniqueElementsBy = (arr, fn) => arr.reduce((acc, v) => {
  if (!acc.some(x => fn(v, x))) acc.push(v)
  return acc
}, [])

/**
 * Creates an array of arrays, ungrouping the elements in an array produced by zip.
 *
 * Use Math.max.apply() to get the longest subarray in the array, Array.prototype.map() to make each element an array.
 * Use Array.prototype.reduce() and Array.prototype.forEach() to map grouped values to individual arrays.
 *
 * @example unzip([['a', 1, true], ['b', 2, false]]); // [['a', 'b'], [1, 2], [true, false]]
 * @example unzip([['a', 1, true], ['b', 2]]); // [['a', 'b'], [1, 2], [true]]
 *
 * @param arr
 * @returns {*}
 */
export const unzip = arr => arr.reduce((acc, val) => (val.forEach((v, i) => acc[i].push(v)), acc), Array.from({ length: Math.max(...arr.map(x => x.length)) }).map(x => []))

/**
 * Creates an array of elements, ungrouping the elements in an array produced by zip and applying the provided function.
 *
 * Use Math.max.apply() to get the longest subarray in the array, Array.prototype.map() to make each element an array.
 * Use Array.prototype.reduce() and Array.prototype.forEach() to map grouped values to individual arrays.
 * Use Array.prototype.map() and the spread operator (...) to apply fn to each individual group of elements.
 *
 * @example unzipWith([[1, 10, 100], [2, 20, 200]], (...args) => args.reduce((acc, v) => acc + v, 0)); // [3, 30, 300]
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
export const unzipWith = (arr, fn) => arr.reduce((acc, val) => (val.forEach((v, i) => acc[i].push(v)), acc), Array.from({ length: Math.max(...arr.map(x => x.length)) }).map(x => [])).map(val => fn(...val))

/**
 * Filters out the elements of an array, that have one of the specified values.
 *
 * Use Array.prototype.filter() to create an array excluding(using !Array.includes()) all given values.
 *
 * @example without([2, 1, 2, 3], 1, 2); // [3]
 *
 * @param arr
 * @param args
 * @returns {*}
 */
export const without = (arr, ...args) => arr.filter(v => !args.includes(v))

/**
 * Creates an array of elements, grouped based on the position in the original arrays.
 *
 * Use Math.max.apply() to get the longest array in the arguments. Creates an array with that length as return value and use
 * Array.from() with a map-function to create an array of grouped elements. If lengths of the argument-arrays vary, undefined
 * is used where no value could be found.
 *
 * @example zip(['a', 'b'], [1, 2], [true, false]); // [['a', 1, true], ['b', 2, false]]
 * @example zip(['a'], [1, 2], [true, false]); // [['a', 1, true], [undefined, 2, false]]
 *
 * @param arrays
 * @returns {*[][]}
 */
export const zip = (...arrays) => {
  const maxLength = Math.max(...arrays.map(x => x.length))
  return Array.from({ length: maxLength }).map((_, i) => {
    return Array.from({ length: arrays.length }, (_, k) => arrays[k][i])
  })
}

/**
 * Given an array of valid property identifiers and an array of values, return an object associating the properties to the values.
 *
 * Since an object can have undefined values but not undefined property pointers, the array of properties is used to decide the
 * structure of the resulting object using Array.prototype.reduce().
 *
 * @example zipObject(['a', 'b', 'c'], [1, 2]); // {a: 1, b: 2, c: undefined}
 * @example zipObject(['a', 'b'], [1, 2, 3]); // {a: 1, b: 2}
 *
 * @param props
 * @param values
 * @returns {*}
 */
export const zipObject = (props, values) => props.reduce((obj, prop, index) => ((obj[prop] = values[index]), obj), {})

/**
 * Creates an array of elements, grouped based on the position in the original arrays and using function as the last value to specify how grouped values should be combined.
 *
 * Check if the last argument provided is a function. Use Math.max() to get the longest array in the arguments.
 * Creates an array with that length as return value and use Array.from() with a map-function to create an array of grouped elements.
 * If lengths of the argument-arrays vary, undefined is used where no value could be found. The function is invoked with the elements of each group (...group).
 *
 * @example zipWith([1, 2], [10, 20], [100, 200], (a, b, c) => a + b + c); // [111,222]
 * @example zipWith(
 * [1, 2, 3],
 * [10, 20],
 * [100, 200],
 * (a, b, c) => (a != null ? a : 'a') + (b != null ? b : 'b') + (c != null ? c : 'c')
 * ); // [111, 222, '3bc']
 *
 * @param array
 * @returns {*[][]}
 */
export const zipWith = (...array) => {
  const fn = typeof array[array.length - 1] === 'function' ? array.pop() : undefined
  return Array.from({ length: Math.max(...array.map(a => a.length)) }, (_, i) => fn ? fn(...array.map(a => a[i])) : array.map(a => a[i]))
}

/**
 * Sums the values of the array, or the properties, or the result of the callback.
 *
 * @param  {Array} [payload=null] the array to be summed.
 * @param  {undefined|string|function} [property=null] the property to be summed.
 * @return {*} The sum of values used in the summation.
 * @example <caption>Summing elements</caption>
 * console.log(sum([1, 2, 3])); // 6
 *
 * @example <caption>Summing a property</caption>
 * console.log(collection.sum([
 *     { name: 'Arya Stark', age: 9 },
 *     { name: 'Bran Stark', age: 7 },
 *     { name: 'Jon Snow', age: 14 }
 * ], 'age')); // 30
 *
 * @example <caption>Summing using a callback</caption>
 * console.log(collection.sum([
 *     { name: 'Arya Stark', age: 9 },
 *     { name: 'Bran Stark', age: 7 },
 *     { name: 'Jon Snow', age: 14 }
 * ], i => i.age + 1)); // 33
 */
export const sum = (payload = [], property = null) => {
  if (typeof property === 'string') return payload.reduce((previous, current) => (previous || 0) + (current[property] || 0), 0)
  if (typeof property === 'function') return payload.reduce((previous, current) => (previous || 0) + (property(current) || 0), 0)
  return payload.reduce((previous, current) => (previous || 0) + (current || 0), 0)
}

/**
 * Checks two arrays are equal with Same Length & Each Value Equal
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {false}
 */
export const arrayEquals = (a, b) => isArray(a) && isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
