export default {
  Home: {
    Title: 'Welcome',
    Index: '01',
    Meta : {
      Title: '@:Route.Home.Title',
      Desc : '',
      Img  : ''
    }
  },
  OurPeople: {
    Title: 'Our People',
    Index: '02',
    Meta : {
      Title: '@:Route.OurPeople.Title',
      Desc : '',
      Img  : ''
    }
  },
  Services: {
    Title: 'Services',
    Index: '03',
    Meta : {
      Title: '@:Route.Services.Title',
      Desc : '',
      Img  : ''
    }
  },
  SuccessStories: {
    Title: 'Success Stories',
    Index: '04',
    Meta : {
      Title: '@:Route.SuccessStories.Title',
      Desc : '',
      Img  : ''
    }
  },
  ValueProposition: {
    Title: 'Value Proposition',
    Index: '05',
    Meta : {
      Title: '@:Route.ValueProposition.Title',
      Desc : '',
      Img  : ''
    }
  },
  Facilities: {
    Title: 'Facilities',
    Index: '06',
    Meta : {
      Title: '@:Route.Facilities.Title',
      Desc : '',
      Img  : ''
    }
  },
  Csr: {
    Title: 'CSR',
    Index: '07',
    Meta : {
      Title: '@:Route.Csr.Title',
      Desc : '',
      Img  : ''
    }
  },
  Contact: {
    Title: 'Contact',
    Index: '08',
    Meta : {
      Title: '@:Route.Contact.Title',
      Desc : '',
      Img  : ''
    }
  }
}
