<template>
  <v-dialog
    v-model="isVisible"
    class="elevation-0"
    fullscreen
    persistent
    scrollable
    transition=""
  >
    <v-card
      class="elevation-0"
      color="transparent"
      flat
      tile
    >
      <v-card-text style="background-color: #E60076; height: 100%;">
        <v-container
          class="fill-height"
          fluid
        >
          <v-row>
            <v-col
              class="text-center section-title white--text"
              cols="12"
            >
              <v-img
                class="mb-16"
                contain
                height="55"
                src="/img/logo/logo-light.png"
              />

              <div class="mt-16">
                <p>Turn to vertical for a better experience during navigation.</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogVisible from '@/mixins/dialog/dialogVisible'

export default {
  name      : 'OrientationDialog',
  components: {},
  directives: {},
  mixins    : [dialogVisible],
  props     : {},
  enums     : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
/deep/ .v-dialog {
  border-radius : 0;
  box-shadow    : initial;
}
</style>
