<template>
  <v-container
    v-if="hasTitle || hasItems"
    class="text-center"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <h2
          v-if="hasTitle"
          :class="titleClass"
        >
          <slot name="title">
            <span v-html="title" />
          </slot>
        </h2>
      </v-col>
    </v-row>

    <v-row v-if="hasItems">
      <v-col cols="12">
        <slot
          :items="items"
          name="items"
        >
          <v-container fluid>
            <v-row class="justify-center">
              <template v-for="(item, index) in items">
                <v-col
                  :key="`item-${index}`"
                  cols="auto"
                >
                  <router-link :to="item.Route && item.Route">
                    <zoom-center-transition
                      :delay="index*100"
                      :duration="200"
                    >
                      <v-avatar
                        v-show="animateItems"
                        :size="itemSize"
                      >
                        <v-img
                          :src="item.Img"
                          :width="itemSize"
                        />
                      </v-avatar>
                    </zoom-center-transition>

                    <fade-transition
                      :delay="index*100"
                      :duration="200"
                    >
                      <div
                        v-if="item.Title"
                        v-show="animateItems"
                        :style="`max-width: calc(${itemSize}px + 24px)`"
                        class="mt-3 feature-item-title"
                        v-html="item.Title"
                      />
                    </fade-transition>
                  </router-link>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name      : 'CmsFeatureBlock',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    title: {
      type   : String,
      default: ''
    },
    titleClass: {
      type   : String,
      default: 'feature-title'
    },

    items: {
      type   : Array,
      default: undefined
    },

    itemSize: {
      type   : Number,
      default: 120
    }
  },
  enums: {},
  data () {
    return {
      animateItems: false
    }
  },
  computed: {
    hasTitle () {
      return !!this.title || !!this.$slots.title
    },

    hasItems () {
      return (Array.isArray(this.items) && this.items.length) || !!this.$slots.items
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.animateItems = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
a:link {
  text-decoration : none;
  color           : #FFFFFF;
}

a:visited {
  text-decoration : none;
  color           : #FFFFFF;
}

a:hover {
  text-decoration : none;
  color           : #FFFFFF;
}

a:active {
  text-decoration : none;
  color           : #FFFFFF;
}

.router-link-active, .vertical-active {
  color : #FFFFFF !important;
}
</style>
