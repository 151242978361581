<template>
  <v-container
    align-start
    fill-height
    fluid
    pa-0
  >
    <portal-target name="video-destination-desktop" />

    <v-row v-if="isMobile">
      <v-col cols="12">
        <portal-target name="video-destination-mobile" />
      </v-col>
    </v-row>

    <v-row
      class="mt-0 px-sm-0 px-md-3"
      style="isolation: isolate;"
    >
      <v-col
        class="pt-0 pt-md-3"
        cols="12"
        md="6"
        offset-md="3"
        offset-xl="4"
        style="border: 0px red solid;"
        xl="4"
      >
        <div :style="`margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight : 0}px; padding: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.SectionPadding : 0}px;`">
          <v-window
            v-model="step"
            class="overflow-visible"
            show-arrows
          >
            <template v-for="(section, index) in data">
              <v-window-item :key="`window-item-${index}`">
                <slide-y-up-transition :delay="100">
                  <v-card
                    v-show="animateItems"
                    :color="section.Color"
                    flat
                    tile
                  >
                    <v-card-title class="section-title px-6">
                      {{ section.Title }}
                    </v-card-title>

                    <v-card-text
                      :style="$vuetify.breakpoint.mdAndUp ?
                        `height: calc(100vh - 65px - ${$dataStore.App.Config.ToolbarHeight}px - ${$dataStore.App.Config.FooterHeight}px - ${$dataStore.App.Config.SectionPadding*4}px)` :
                        `height: calc(100vh - 300px - ${$dataStore.App.Config.FooterHeight}px)`
                      "
                      class="section-body white--text px-6 overflow-y-auto"
                      v-html="section.Body"
                    />
                  </v-card>
                </slide-y-up-transition>
              </v-window-item>
            </template>
          </v-window>
        </div>
      </v-col>
    </v-row>

    <portal :to="`video-destination-${portalTarget}`">
      <video-background
        :poster="data[step].Background.Image"
        :src="data[step].Background.Video"
        :style="$vuetify.breakpoint.mdAndUp ? `position: absolute; height: 100vh; width: 100vw;` : `height: 250px; width: 100%;`"
      >
        <v-container
          v-if="isMobile"
          fill-height
          fluid
          pa-0
        >
          <v-row>
            <v-col
              class="text-right"
              cols="12"
            >
              <div
                :style="`min-width: 125px; background-color: ${data[step].Color}; color: #FFFFFF; font-size: 20px;`"
                class="d-inline-block text-left mt-12 px-4 py-2 font-weight-medium"
              >
                {{ data[step].Title }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </video-background>
    </portal>
  </v-container>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'

export default {
  name      : 'Csr',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      formSuccess : false,
      animateItems: false,
      step        : 0,
      data        : [
        {
          Title: 'Our People',
          Body : `
            <p>We are committed to make Each Interaction Matter by valuing our people and being a top employer, by respecting our interaction with the environment, and by promoting equality, inclusion, and diversity.</p>
            <p>An awarded Great Place to Work and One of the 25 World’s Best Workplaces in 2021 recognized by our employees which establishes a culture of engagement and a workplace that inspires and helps our people reach their full potential.</p>
            <p>Happy employees make happy customers, so we constantly invest in well-being, work life balance and development. We help our people grow in an environment that embraces and hosts 10K+ people from 121 different nationalities. A long agenda with benefits ranging from health & prevention initiatives to programs aiming to support the needs of the families of our people, to various training and development workshops. </p>
            <p><img src="/img/pages/csr/img-1.png" width="100%" height="auto"></p>
          `,
          Color     : 'rgba(0,171,150,0.9)',
          Background: { Image: '/img/pages/csr/bg-1.jpg', Video: '' }
        },
        {
          Title: 'Society',
          Body : `
            <p>Focusing and caring about people is in our DNA, as a large and multicultural organization employing people with diverse backgrounds, we are committed in bringing people together, creating meaningful interactions and build a sense of uniqueness and belonging.</p>
            <p>We break down barriers and with respect to people, through our Impact Sourcing we focus on creating opportunities for vulnerable communities such as people with disabilities, refugees, migrants and long-term unemployed. Through strong partnerships with different associations and NGOs we aim at impacting as many lives as possible.</p>
            <p>Diversity & Inclusion forms the heart of our identity and through our multiple campaigns and programs we engage our people, and promote an open culture, essential to the continuous improvement and transformation of our company. We support and promote, gender, cultural, religious, and sexual diversity. We sponsor and support the LGBTIQ community, we celebrate our cultures with dedicated initiatives and events and our TP Women campaign promotes gender diversity and gender equality. For us, diversity is measurable. Figures and data prove our commitment. Together we can always achieve more.</p>
          `,
          Color     : 'rgba(122,33,130,0.9)',
          Background: { Image: '/img/pages/csr/bg-2.jpg', Video: '' }
        },
        {
          Title: 'Environment',
          Body : `
            <p>Raising environmental awareness is a fundamental part of our strategy. We remain committed in reducing our environmental impact by adopting actions that aim for a carbon-constrained world. Teleperformance is a signatory of the United Nations Global Compact, which was established to promote sustainable development, environmental protection, anti-corruption and human rights. Teleperformance commits itself by adhering not only to these principles but also implementing them on an operational level for its customers around the world.</p>
            <p>We integrated sustainable mobility in our CSR strategy. We want to be the driving force behind the new, clean energy economy by consistently driving forward promoting electric mobility and networks for everyone's benefit!</p>
            <p>With our mindset and actions around this mission, we organize a range of initiatives, from reforestations and ocean cleaning to recycling actions, earth celebrations and awareness campaigns. With a commitment to plant thousands of trees within the year, to clear our nearby beaches from the plastic, we ensure and encourage our people towards volunteerism and green behavior.</p>
          `,
          Color     : 'rgba(230,0,118,0.9)',
          Background: { Image: '/img/pages/csr/bg-3.jpg', Video: '' }
        }
      ]
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },

    portalTarget () {
      return this.$vuetify.breakpoint.smAndDown ? 'mobile' : 'desktop'
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.animateItems = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .vue-responsive-videobg .video-buffering {
  background-position : top center;
}

/deep/ .vue-responsive-videobg .video-wrapper video {
  object-fit      : cover;
  object-position : top center;
}

/deep/ .v-window__next, /deep/ .v-window__prev {
  margin : 0 -48px;
}

@media screen and (max-width : 959px) {
  /deep/ .v-window__next, /deep/ .v-window__prev {
    margin : 0 -12px;
  }
}
</style>
