/**
 * Returns the average of two or more numbers.
 *
 * Use Array.prototype.reduce() to add each value to an accumulator, initialized with a value of 0, divide by the length of the array.
 *
 * @example
 * average(...[1, 2, 3]); // 2
 * average(1, 2, 3); // 2
 *
 * @param nums
 * @return {number}
 */
export const average = (...nums) => nums.reduce((acc, val) => acc + val, 0) / nums.length

/**
 * Returns the average of an array, after mapping each element to a value using the provided function.
 *
 * Use Array.prototype.map() to map each element to the value returned by fn, Array.prototype.reduce()
 * to add each value to an accumulator, initialized with a value of 0, divide by the length of the array.
 *
 * @example
 * averageBy([{ n: 4 }, { n: 2 }, { n: 8 }, { n: 6 }], o => o.n); // 5
 * averageBy([{ n: 4 }, { n: 2 }, { n: 8 }, { n: 6 }], 'n'); // 5
 *
 * @param arr
 * @param fn
 * @return {number}
 */
export const averageBy = (arr, fn) => arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val) => acc + val, 0) / arr.length

/**
 * Converts an angle from degrees to radians.
 *
 * Use Math.PI and the degree to radian formula to convert the angle from degrees to radians.
 *
 * @example degreesToRads(90.0); // ~1.5708
 *
 * @param deg
 * @return {number}
 */
export const degreesToRads = deg => (deg * Math.PI) / 180.0

/**
 * Converts a number to an array of digits.
 *
 * Convert the number to a string, using the spread operator (...) to build an array.
 * Use Array.prototype.map() and parseInt() to transform each value to an integer.
 *
 * @example digitize(123); // [1, 2, 3]
 *
 * @param n
 * @return {number[]}
 */
export const digitize = n => [...`${ n }`].map(i => parseInt(i))

/**
 * Returns the distance between two points.
 *
 * Use Math.hypot() to calculate the Euclidean distance between two points.
 *
 * @example distance(1, 1, 2, 3); // 2.23606797749979
 *
 * @param x0
 * @param y0
 * @param x1
 * @param y1
 * @return {number}
 */
export const distance = (x0, y0, x1, y1) => Math.hypot(x1 - x0, y1 - y0)

/**
 * Checks if the given number falls within the given range.
 *
 * Use arithmetic comparison to check if the given number is in the specified range.
 * If the second parameter, end, is not specified, the range is considered to be from 0 to start.
 *
 * @example
 * inRange(3, 2, 5); // true
 * inRange(3, 4); // true
 * inRange(2, 3, 5); // false
 * inRange(3, 2); // false
 *
 * @param n
 * @param start
 * @param end
 * @return {boolean}
 */
export const inRange = (n, start, end = null) => {
  if (end && start > end) [end, start] = [start, end]
  return end == null ? n >= 0 && n < start : n >= start && n < end
}

/**
 * Checks if the first numeric argument is divisible by the second one.
 *
 * Use the modulo operator (%) to check if the remainder is equal to 0.
 *
 * @example isDivisible(6, 3); // true
 *
 * @param dividend
 * @param divisor
 * @return {boolean}
 */
export const isDivisible = (dividend, divisor) => dividend % divisor === 0

/**
 * Returns true if the given number is even, false otherwise.
 *
 * Checks whether a number is odd or even using the modulo (%) operator. Returns true if the number is even, false if the number is odd.
 *
 * @example isEven(3); // false
 *
 * @param num
 * @return {boolean}
 */
export const isEven = num => num % 2 === 0

/**
 * Checks if the given value is equal to negative zero (-0).
 *
 * Checks whether a passed value is equal to 0 and if 1 divided by the value equals -Infinity.
 *
 * @example
 * isNegativeZero(-0); // true
 * isNegativeZero(0); // false
 *
 * @param val
 * @return {boolean}
 */
export const isNegativeZero = val => val === 0 && 1 / val === -Infinity

/**
 * Returns true if the given number is odd, false otherwise.
 *
 * Checks whether a number is odd or even using the modulo (%) operator. Returns true if the number is odd, false if the number is even.
 *
 * @example isOdd(3); // true
 *
 * @param num
 * @return {boolean}
 */
export const isOdd = num => num % 2 === 1

/**
 * Checks if the provided integer is a prime number.
 *
 * Check numbers from 2 to the square root of the given number.
 * Return false if any of them divides the given number, else return true, unless the number is less than 2.
 *
 * @example isPrime(11); // true
 *
 * @param num
 * @return {boolean}
 */
export const isPrime = num => {
  const boundary = Math.floor(Math.sqrt(num))
  for (let i = 2; i <= boundary; i++) if (num % i === 0) return false
  return num >= 2
}

/**
 * Maps a number from one range to another range.
 *
 * Returns num mapped between outMin-outMax from inMin-inMax.
 *
 * @example mapNumRange(5, 0, 10, 0, 100); // 50
 *
 * @param num
 * @param inMin
 * @param inMax
 * @param outMin
 * @param outMax
 * @return {*}
 */
export const mapNumRange = (num, inMin, inMax, outMin, outMax) => ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin

/**
 * Calculates the midpoint between two pairs of (x,y) points.
 *
 * Destructure the array to get x1, y1, x2 and y2, calculate the midpoint for each dimension by dividing the sum of the two endpoints by 2.
 *
 * @example midpoint([2, 2], [4, 4]); // [3, 3]
 * @example midpoint([4, 4], [6, 6]); // [5, 5]
 * @example midpoint([1, 3], [2, 4]); // [1.5, 3.5]
 *
 * @param x1
 * @param y1
 * @param x2
 * @param y2
 * @return {*[]}
 */
export const midpoint = ([x1, y1], [x2, y2]) => [(x1 + x2) / 2, (y1 + y2) / 2]

/**
 * Uses the percentile formula to calculate how many numbers in the given array are less or equal to the given value.
 *
 * Use Array.prototype.reduce() to calculate how many numbers are below the value and how many are the same value and apply the percentile formula.
 *
 * @example percentile([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 6); // 55
 *
 * @param arr
 * @param val
 * @return {number}
 */
export const percentile = (arr, val) => (100 * arr.reduce((acc, v) => acc + (v < val ? 1 : 0) + (v === val ? 0.5 : 0), 0)) / arr.length

/**
 * Converts an angle from radians to degrees.
 *
 * Use Math.PI and the radian to degree formula to convert the angle from radians to degrees.
 *
 * @example radsToDegrees(Math.PI / 2); // 90
 *
 * @param rad
 * @return {number}
 */
export const radsToDegrees = rad => (rad * 180.0) / Math.PI

/**
 * Returns an array of n random integers in the specified range.
 *
 * Use Array.from() to create an empty array of the specific length, Math.random() to
 * generate a random number and map it to the desired range, using Math.floor() to make it an integer.
 *
 * @example  randomIntArrayInRange(12, 35, 10); // [ 34, 14, 27, 17, 30, 27, 20, 26, 21, 14 ]
 *
 * @param min
 * @param max
 * @param n
 * @return {*[]}
 */
export const randomIntArrayInRange = (min, max, n = 1) => Array.from({ length: n }, () => Math.floor(Math.random() * (max - min + 1)) + min)

/**
 * Returns a random integer in the specified range.
 *
 * Use Math.random() to generate a random number and map it to the desired range, using Math.floor() to make it an integer.
 *
 * @example randomIntegerInRange(0, 5); // 2
 *
 * @param min
 * @param max
 * @return {*}
 */
export const randomIntegerInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

/**
 * Returns a random number in the specified range.
 *
 * Use Math.random() to generate a random value, map it to the desired range using multiplication.
 *
 * @example randomNumberInRange(2, 10); // 6.0211363285087005
 *
 * @param min
 * @param max
 * @return {*}
 */
export const randomNumberInRange = (min, max) => Math.random() * (max - min) + min

/**
 * Rounds a number to a specified amount of digits.
 *
 * Use Math.round() and template literals to round the number to the specified number of digits.
 * Omit the second argument, decimals to round to an integer.
 *
 * @example round(1.005, 2); // 1.01
 *
 * @param n
 * @param decimals
 * @return {number}
 */
export const round = (n, decimals = 0) => Number(`${ Math.round(`${ n }e${ decimals }`) }e-${ decimals }`)

/**
 * Converts a value to a safe integer.
 *
 * Use Math.max() and Math.min() to find the closest safe value. Use Math.round() to convert to an integer.
 *
 * @example toSafeInteger('3.2'); // 3
 * @example toSafeInteger(Infinity); // 9007199254740991
 *
 * @param num
 * @return {number}
 */
export const toSafeInteger = num => Math.round(Math.max(Math.min(num, Number.MAX_SAFE_INTEGER), Number.MIN_SAFE_INTEGER))
