<template>
  <v-container
    fluid
    pa-0
  >
    <v-row class="">
      <v-col
        cols="12"
        lg="6"
        md="8"
        sm="12"
      >
        <video-background
          :style="$vuetify.breakpoint.mdAndUp ? `position: absolute; height: 100vh; width: 100vw;` : `height: 250px; width: 100%;`"
          poster="/video/our-people.jpg"
          src="/video/our-people.mp4"
        />
      </v-col>
    </v-row>

    <v-row
      :style="$vuetify.breakpoint.mdAndUp ? `` : `background-color: #28348B;`"
      class="mt-0 px-3"
      style="isolation: isolate;"
    >
      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="12"
        style="border: 0px red solid;"
      >
        <div :style="`margin-top: ${$vuetify.breakpoint.mdAndUp ? $dataStore.App.Config.ToolbarHeight : 0}px; padding-left: ${$dataStore.App.Config.SectionPadding}px; padding-top: ${$dataStore.App.Config.SectionPadding}px;`">
          <slide-y-up-transition :delay="150">
            <div
              v-show="animateItems"
              :style="$vuetify.breakpoint.mdAndUp ?
                `background-color: rgba(40,52,139,0.8); padding: ${$dataStore.App.Config.SectionPadding}px; height: calc(100vh - 100px - ${$dataStore.App.Config.ToolbarHeight + $dataStore.App.Config.FooterHeight + $dataStore.App.Config.SectionPadding * 2}px);` :
                `padding: ${$dataStore.App.Config.SectionPadding}px; height: calc(100vh - 300px - ${$dataStore.App.Config.FooterHeight - 26}px);`"
              style="border: 0px green solid;"
            >
              <div
                class="overflow-y-auto px-2"
                style="height: calc(100% - 8px)"
              >
                <div style="border: 0px orange solid;">
                  <cms-text-block>
                    <template #title>
                      Top talent Acquisition
                    </template>

                    <template #body>
                      <p>Based on our psychometric analysis the ideal high performer agent holds a combination of personality traits that allow high burnout resistance and fast information processing.</p>
                      <p>
                        We focus on a new tech savvy profile since it’s the new generation that will play a key role in driving the transformation of the automotive industry.
                        <br>
                        We create and maintain a global pool of recruiting talents for over 45 languages, through our structured Sourcing Strategy and Actions:
                      </p>
                      <p>
                        <ul>
                          <li>A fully virtual recruitment model.</li>
                          <li>The TP Cloud Campus, our Work at Home model</li>
                          <li>We offer the option of having remote, virtual operations as a long-term, services strategy.</li>
                        </ul>
                      </p>
                      <p>We can ensure sustainable growth and high-quality services by using 100% tailor-made assessment techniques as to select the right person, in the right position, at the right time.</p>
                    </template>
                  </cms-text-block>

                  <div
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="my-8"
                  >
                    <slide-x-left-transition>
                      <cms-button
                        v-show="animateItems"
                        @click.native="showDialog"
                      >
                        SPECIAL TRAINING APPROACH
                        <template #append>
                          <v-icon right>
                            mdi-chevron-right
                          </v-icon>
                        </template>
                      </cms-button>
                    </slide-x-left-transition>
                  </div>
                </div>
              </div>
            </div>
          </slide-y-up-transition>

          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mt-8"
          >
            <slide-x-left-transition :delay="100">
              <cms-button
                v-show="animateItems"
                @click.native="showDialog"
              >
                SPECIAL TRAINING APPROACH
                <template #append>
                  <v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </template>
              </cms-button>
            </slide-x-left-transition>
          </div>
        </div>
      </v-col>
    </v-row>

    <special-training-approach-dialog :visible.sync="dialogVisible" />
  </v-container>
</template>

<script>
import i18nRouteMeta                 from '@/mixins/i18n/i18nRouteMeta'
import CmsTextBlock                  from '@/theme/default/components/cms/CmsTextBlock'
import CmsButton                     from '@/theme/default/components/common/cms-button'
import SpecialTrainingApproachDialog from '@/theme/default/views/our-people/SpecialTrainingApproachDialog'

export default {
  name      : 'OurPeople',
  components: { SpecialTrainingApproachDialog, CmsButton, CmsTextBlock },
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      animateItems : false,
      dialogVisible: false
    }
  },
  computed: {},
  watch   : {
    dialogVisible (newVal) {
      this.animateItems = !newVal
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.animateItems = true
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    showDialog () {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>
/deep/ .vue-responsive-videobg .video-buffering {
  background-position : top center;
}

/deep/ .vue-responsive-videobg .video-wrapper video {
  object-fit      : cover;
  object-position : top center;
}
</style>
